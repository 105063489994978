import useUiConfigs from "../../../hooks/use-ui-config.hook";
import CompactExternalDataList from "./components/compact-data-list/compact-external-data-list.component";
import {Spinner, SpinnerSize} from "@fluentui/react";
import CompactStandardDataTable from "./components/compact-standard-data-table/compact-standard-data-table.component";
import EditButtonComponent from "../../../common/edit-button/edit-button.component";

const DefaultDataTable = ({quickAction = false, id, type}: any) => {

    const {data} = useUiConfigs('DLAD C Table', id)

    let markup = (<Spinner size={SpinnerSize.medium}/>)

    if (data)
        switch (data.type) {
            case 'erp':
                markup = (<CompactExternalDataList config={data}/>);
                break;
            case 'standard':
                markup = (<CompactStandardDataTable config={data}/>)
                break;
        }

    return (
        <>
            <div className={'mx-2'}>
                {markup}
            </div>
        </>
    )
}

export default DefaultDataTable