import erpResourceApi from "../../../../services/erp-resource-api.service";
import googleMapApi from "../../../../services/google-map.service";

export const getFilteredSiteVisits = (date: any) => {
    return async (dispatch: any) => {
        if (date) {
            const {error, data} = await dispatch(erpResourceApi.endpoints.list.initiate({
                method: 'DLAD Site Visit',
                fields: ['*'],
                filters: JSON.stringify([['DLAD Site Visit', 'start_time', 'like', date + '%']])
            }))

            return data
        }

        return []
    }
}

export const getGeoDodeList = (list: []) => {
    return async (dispatch: any) => {
        let geocodeList: any = []

        if (list) {
            for (const {street, city, state_province, postal_code, party} of list) {
                const {error, data} = await dispatch(googleMapApi.endpoints.geocode.initiate({
                    address: `${street}, ${city}, ${state_province}, ${postal_code}`
                }))
                let location = data?.results[0] ? data?.results[0].geometry.location : {}
                let bp = party
                geocodeList.push({location, bp})
            }

        }
        return geocodeList
    }
}