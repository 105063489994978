import {useRoutes} from "react-router-dom";
import DefaultExternalLayout from "../components/layouts/external/default-external.layout";
import DefaultInternalLayout from "../components/layouts/internal/default-internal/default-interal.layout";
import HomePage from "../components/pages/home/home.page";
import LoginPage from "../components/pages/login/login.page";
import GeneralContainerPage from "../components/pages/general-container-page/general-container.page";
import UnauthorizedAlert from "../components/common/unauthorized-alert/unauthorized-alert.component";
import WelcomeAlert from "../components/common/welcome-alert/welcome-alert.component";

const RouterConfigs = () => {
    const element = useRoutes([
        {
            path: "/",
            element: <DefaultExternalLayout/>,
            children: [
                {
                    index: true,
                    element: <LoginPage/>
                }
            ]
        },
        {
            path: "/app",
            element: <DefaultInternalLayout/>,
            children: [
                {index: true, element: <WelcomeAlert/>},
                {
                    path: '/app/page/:name',
                    element: <GeneralContainerPage/>
                }
            ],
        },
        {path: "*", element: <h1>page not found</h1>},
    ]);

    return element;
};

export default RouterConfigs;
