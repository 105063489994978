import {erpResourceApi} from "../../../services/erp-resource-api.service";
import {setAppConfig, setPage, setWorkspace} from "../../reducers/general-ui/general-ui.store";
import {erpMethodApi} from "../../../services/erp-method-api.service";
import {setModules} from "../../reducers/general-ui/internal-layout/sidebar.store";

export const getAppConfigAction = () => {
    return async (dispatch: any) => {
        const {error, data} = await dispatch(erpResourceApi.endpoints.get.initiate({
            doc: 'DLAD UI Settings',
            name: 'DLAD UI Settings'
        }))

        if (data)
            dispatch(setAppConfig(data.data))
    }
}

export const getModulesAction = () => {
    return async (dispatch: any) => {
        const {error, data} = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: 'dlad_core.ui.workspace.list'
        }))

        if (data)
            dispatch(setModules(data))
    }
}

export const getWorkspaceAction = (payload: any) => {
    return async (dispatch: any) => {
        const {error, data}: any = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: 'dlad_core.ui.workspace.get',
            body: payload
        }))

        if (data)
            dispatch(setWorkspace(data.message.navigation))
    }
}

export const getPageConfigAction = (payload: any) => {
    return async (dispatch: any) => {
        const response: any = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: 'dlad_core.ui.page.get',
            body: payload
        }))

        if (response.isError && response.error.data.exc_type == 'PermissionError') {
            dispatch(setPage('unauthorized'))
        } else if (response && response.data) {
            dispatch(setPage(response.data.message))
        }

    }
}