import {
    DatePicker,
    DayOfWeek,
    defaultDatePickerStrings,
    FontIcon, IDatePickerStyles,
    ITextFieldStyles,
    KeyCodes,
    TextField
} from "@fluentui/react";
import React from "react";
import moment from 'moment'
import _ from "lodash";

const styles: Partial<IDatePickerStyles> = {
    textField: {width: 250}
};

const InlineDateEditor = ({model, path, setEditMode, onChange, onKeyDown}: any) => {

    const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(DayOfWeek.Sunday);

    const onChangeHandler = (date: any) => {
        onChange(null, moment(date).format('YYYY-MM-DD'))
        onKeyDown({keyCode: KeyCodes.enter})
    }

    return (
        <div className={'d-flex align-items-center'}>
            <DatePicker
                firstDayOfWeek={firstDayOfWeek}
                placeholder="Select a date..."
                ariaLabel="Select a date"
                onKeyDown={onKeyDown}
                onSelectDate={onChangeHandler}
                strings={defaultDatePickerStrings}
                styles={styles}
            />
            <FontIcon className={'ps-2'} iconName={'Cancel'} onClick={() => onKeyDown({keyCode: KeyCodes.enter})}/>
        </div>
    )
}

export default InlineDateEditor