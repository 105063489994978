import AlertDialog from "../../../common/alert-dialog/alert-dialog.component";
import InfoBubble from "../../../common/info-bubble/info-bubble.component";
import ConfigEditPanel from "../../../apps/admin/config-edit-panel/config-edit-panel.component";

const RootUi = ({children}: any) => {

    return (
        <>
            {children}
            <AlertDialog/>
            <InfoBubble/>
            <ConfigEditPanel/>
        </>
    )
}

export default RootUi