import {
    CommandBar,
    ContextualMenu,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    Dialog,
    DialogFooter,
    DialogType,
    IColumn,
    ICommandBarItemProps, Icon,
    IconButton,
    IDragDropContext,
    ISelection, IStackTokens,
    mergeStyles,
    PrimaryButton,
    Selection,
    Stack,
    Text,
    TextField
} from "@fluentui/react";
import React, {useEffect, useRef, useState} from "react";
import useAutoFit from "../../../../hooks/use-auto-fit.hook";
import useSafeQuery from "../../../../hooks/use-safe-query.hook";
import {useGetQuery} from "../../../../../services/erp-resource-api.service";
import {save} from "../../actions/generic.actions";
import {useDispatch} from "react-redux";
import _ from 'lodash'
import {useBoolean} from "@fluentui/react-hooks";

const TupleCardContainerConfig = (props: any) => {
    let selection: ISelection = new Selection()
    let _draggedItem: any
    let _draggedIndex: any

    const dispatch = useDispatch()
    const [groups, setGroups] = useState([])
    const [lines, setLines] = useState([])
    const [editableList, setEditableList] = useState([])
    const [ref, setAutoFit] = useAutoFit()
    const [editingValue, setEditingValue] = useState('')
    const updatedDataList = useRef([])

    const [hideDialog, {toggle: toggleHideDialog}] = useBoolean(true);
    const [isDraggable, {toggle: toggleIsDraggable}] = useBoolean(false);

    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: isDraggable ? dragOptions : undefined,
        }),
        [isDraggable],
    );

    const {isSuccess, data} = useSafeQuery(useGetQuery, {doc: props.doctype, name: props.name})

    useEffect(() => {
        if (data && data.data) setLines(data.data.tuples.map((line: any) => ({...line, id: line.idx})))
    }, [data])

    const _items: ICommandBarItemProps[] = [
        {
            key: 'add',
            text: 'New',
            cacheKey: 'add',
            iconProps: {iconName: 'add'},
            onClick: () => {
                let list: any = [...lines, {idx: lines.length, title: '', path: '', type: '', edit: ''}]
                setLines(list)
            }
        },
        {
            key: 'save',
            text: 'Save',
            cacheKey: 'save',
            iconProps: {iconName: 'Save'},
            onClick: () => {
                let model = _.cloneDeep(lines);

                model = model.map((line: any, index: number) => {
                    line.idx = index + 1;
                    return line
                }) as never

                dispatch(save(props.doctype, data.data.name, {tuples: model}))
            }
        },
    ];

    const headerFields: any = [
        // {name: 'idx', label: 'Sequence', editable: false},
        {name: 'title', label: 'Title', editable: true},
        {name: 'path', label: 'Path', editable: true},
        {name: 'type', label: 'Type', editable: false},
        {name: 'edit', editable: false},
    ]

    const minWidth = (window.innerWidth / headerFields.length) - 100

    const columns: IColumn[] = headerFields.map((value: any) => ({
            key: value.name,
            name: value.label,
            fieldName: value.name,
            isResizable: true,
            editable: value.editable,
            minWidth: minWidth,
            maxWidth: minWidth
        })
    )

    const dragEnterClass = mergeStyles({
        backgroundColor: 'red',
    });

    const _insertBeforeItem =
        (item: any) => {
            const draggedItems = selection.isIndexSelected(_draggedIndex)
                ? (selection.getSelection())
                : [_draggedItem!];

            const insertIndex = lines.indexOf(item as never)
            let items: any = lines.filter(itm => draggedItems.indexOf(itm) === -1);

            // @ts-ignore
            items.splice(insertIndex, 0, ...draggedItems);

            setLines(items)
        }

    const _getDragDropEvents = () => {
        return {
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDragEnter: (item?: any, event?: DragEvent) => {
                // return string is the css classes that will be added to the entering element.
                return dragEnterClass;
            },
            onDragLeave: (item?: any, event?: DragEvent) => {
                return;
            },
            onDrop: (item?: any, event?: DragEvent) => {
                if (_draggedItem) {
                    _insertBeforeItem(item);
                }
            },
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                _draggedItem = item;
                _draggedIndex = itemIndex!;
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                _draggedItem = undefined;
                _draggedIndex = -1;
            },
        };
    }

    const _onItemInvoked = (index: number, column: any) => {
        let model = lines[index];
        setEditingValue(model[column.fieldName])
        toggleHideDialog()
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        console.log(column)
        return _.get(model, column.fieldName);
    }

    const className = "overflow-auto"

    return (
        <Stack className={'bg-white rounded-2'}>

            <CommandBar
                items={_items}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
            />
            <Text className="ms-2 mt-1" variant={'smallPlus'}>{props.name}</Text>
            <div>
                <DetailsList
                    items={lines}
                    columns={columns}
                    selection={selection}
                    compact={true}
                    setKey="none"
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    selectionMode={0}
                    // onItemInvoked={_onItemInvoked}
                    onRenderItemColumn={_renderItemColumn}
                    dragDropEvents={_getDragDropEvents()}
                />
            </div>

            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <TextField value={editingValue} onChange={(e, value: any) => {
                    setEditingValue(value)
                }}/>
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialog} text="Send"/>
                    <DefaultButton onClick={toggleHideDialog} text="Don't send"/>
                </DialogFooter>
            </Dialog>

        </Stack>
    );

    function _renderItemColumn(item: any, index: any, column: any) {
        const fieldContent = item[column.fieldName] as string
        let isEditable = editableList.some((line: any) => line == item.name)

        const verticalGapStackTokens: IStackTokens = {
            childrenGap: 10,
            padding: 5
        };

        if (column.key == 'edit') {
            if (isEditable) {
                return (
                    <Stack horizontal tokens={verticalGapStackTokens}>
                        <Icon iconName={'save'} onClick={() => saveChanges(index, item.name)}/>
                        <Icon iconName={'delete'} onClick={() => deleteRow(item.name)}/>
                        <Icon iconName={'ChromeClose'} onClick={() => removeEditableField(item.name)}/>
                    </Stack>
                )
            }

            return (
                <Icon iconName={'edit'} onClick={() => setEditableField(item.name)}/>
            )
        }

        if (isEditable) return (
            <EditableCell index={index} fieldName={column.fieldName} initValue={fieldContent}
                          onChange={(value: any) => {
                              let newList: any = [...updatedDataList.current, value]
                              updatedDataList.current = newList;
                          }
                          }/>
        )

        return <span>{fieldContent}</span>
    }

    function setEditableField(name: any) {
        let list: any = [...editableList, name]
        setEditableList(list)
    }

    function removeEditableField(name: any) {
        let list: any = editableList.filter((line: any) => line != name)
        setEditableList(list)
    }

    function saveChanges(index: number, name: string) {
        let updateList = updatedDataList.current.filter((line: any) => line.index == index)

        if (updateList) {
            let currentList: any = lines
            updateList.forEach((line: any) => {
                currentList[line.index][line.fieldName] = line.value;
            })
            setLines(currentList)
        }
        removeEditableField(name)
    }

    function deleteRow(name: string) {
        let list = lines.filter((line: any) => line.name != name)
        setLines(list)
    }
}

const EditableCell = ({index, initValue, fieldName, onChange}: any) => {
    const [value, setValue] = useState(initValue)

    const _onChangeHandler = (e: any) => {
        setValue(e.target.value)
    }

    return (
        <TextField value={value} onChange={_onChangeHandler} onBlur={(event => {
            onChange({index, fieldName, value})
        })}/>
    )
}


const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
};

const modalPropsStyles = {main: {maxWidth: 450}};
const dialogContentProps = {
    type: DialogType.normal,
    title: 'Edit',
    subText: '',
};

export default TupleCardContainerConfig