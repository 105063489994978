import {createSlice} from '@reduxjs/toolkit'

const initialState = {} as any

const slice = createSlice({
    name: 'erp-data-store',
    initialState,
    reducers: {
        set(state: any, {payload}: any) {
            state[payload._key] = payload.value
        }
    },
})

export const {set} = slice.actions
export default slice.reducer