import _ from "lodash";
import {FontIcon, IDropdownStyles, ITextFieldStyles, KeyCodes, TextField} from "@fluentui/react";

const iconProps = {
    iconName: 'Cancel'
}

const styles: Partial<ITextFieldStyles> = {
    field: {width: 248}
};

const InlineTextEditor = ({model, path, setEditMode, onChange, onKeyDown}: any) => {

    return (
        <div className={'d-flex align-items-center'}>
            <TextField styles={styles} value={_.get(model, path)} onChange={onChange} onKeyDown={onKeyDown}/>
            <FontIcon className={'ps-2'} iconName={'Cancel'} onClick={() =>  onKeyDown({keyCode: KeyCodes.enter})}/>
        </div>
    )
}

export default InlineTextEditor