import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/store";
import {FontIcon, Link, mergeStyles, Shimmer, Text} from "@fluentui/react";
import _ from 'lodash'
import {useEffect, useState} from "react";
import InlineEditor from "../inline-editor/inline-editor.component";
import {useId} from '@fluentui/react-hooks';
import InfoBubble from "../../../common/info-bubble/info-bubble.component";
import {show} from "../../../../store/reducers/general-ui/info-bubble.store";
import ResolvableTupleCard from "./components/resolvable-tuple-card/resolvable-tuple-card.component";
import DefaultTupleCard from "./components/default-tuple-card/default-tuple-card.component";
import LinkTupleCard from "./components/link-tuple-card/link-tuple-card.component";

const iconClass = mergeStyles({
    margin: '0 10px',
    cursor: 'pointer'
});

const TupleCard = ({
                       edit_data_query,
                       is_editable = false,
                       type = '',
                       text = '',
                       path = '',
                       title = '',
                       edit_type,
                       link_page,
                       link_json_path,
                       resolvable_query
                   }: any) => {

    const dispatch = useDispatch()
    const id = useId('id')

    let timer: any;
    const TIMEOUT = 1500;

    const [editMode, setEditMode] = useState(false)
    const {model} = useSelector<RootStore, any>((state) => state.pagesStore.doc)

    // useEffect(() => {
    //     console.log('asdasd', editMode)
    //     if (editMode) console.log(clearTimeout(timer))
    // }, [editMode])

    const mouseEnter = () => {
        if (!is_editable) {
            timer = setTimeout(() => {
                showTeachingBubble()
            }, TIMEOUT);
        }
    }

    const mouseLeave = () => {
        if (!is_editable) {
            clearTimeout(timer)
        }
    }

    const showTeachingBubble = () => {
        if (!editMode) {
            dispatch(show({target: id, model: {}}))
        }

    }

    let dataLineMarkup;

    switch (type) {
        case 'link': {
            dataLineMarkup = (<LinkTupleCard text={_.get(model, path)} page={link_page}/>)
            break;
        }
        default: {
            dataLineMarkup = (<DefaultTupleCard text={_.get(model, path)}/>)
            break;
        }
    }

    let markup;

    switch (type) {
        case 'resolvable-link': {
            markup = (<ResolvableTupleCard value={_.get(model, path)} resolvable_query={resolvable_query}/>)
            break;
        }
        default: {
            markup = editMode ? (
                <InlineEditor model={model} path={path} setEditMode={setEditMode} editType={edit_type}
                              editDataQuery={edit_data_query}/>
            ) : (
                path ?
                    (
                        <span id={id} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                        {dataLineMarkup}
                            {is_editable ? <FontIcon onClick={() => setEditMode(!editMode)} className={iconClass}
                                                     aria-label={iconClass}
                                                     iconName="EditSolid12"/> : <></>}
                        </span>
                    ) : <Shimmer className={'mt-1'} width="90%"/>

            )

            break;
        }
    }

    return (
        <div className={'row gx-0 border-bottom'}>
            <div className={'col-4'}>
                <Text variant={'small'}>{title}</Text>
            </div>
            <div className={'col'}>
                {markup}
            </div>
        </div>
    )
}

// const getType = (type: string, text: String) => {
//     if (type == 'badge-primary') return <Text className={'badge bg-primary text-uppercase'}
//                                               variant={'small'}>{text}</Text>
//     if (type == 'badge-danger') return <Text className={'badge bg-danger text-uppercase'}
//                                              variant={'small'}>{text}</Text>
//     if (type == 'link') return
//
//     return <DefaultTupleCard text={text}/>
// }

export default TupleCard