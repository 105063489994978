import FilterDropdown from "./components/filter-dropdown-component/filter-dropdown.component";
import FilterDatePicker from "./components/filter-date-picker/filter-date-picker.component";

const FILTER_CONFIG: any = [
    {
        type: 'dropdown',
        component: FilterDropdown
    },
    {
        type: 'date picker',
        component: FilterDatePicker
    },
    // {
    //     type: 'slider',
    //     component: SliderComponent
    // }
]

export default FILTER_CONFIG