import {erpResourceApi} from "../../../../services/erp-resource-api.service";
import {erpMethodApi} from "../../../../services/erp-method-api.service"

export const getStandardDocumentList = (model: any) => {
    return async (dispatch: any) => {
        let {data} = await dispatch(erpResourceApi.endpoints.list.initiate({
            method: model
        }));
        return data?.data.map((line: any) => ({key: line.name, text: line.name})) ?? []
    }
}

export const getDocumentList = (model: any) => {
    return async (dispatch: any) => {
        let {data} = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: 'dlad_sap_connector.dlad_sap_connector.api.execute_query',
            body: model
        }));
        return data?.message ?? []
    }
}