import useSafeQuery from "../../../../hooks/use-safe-query.hook";
import {useGetQuery} from "../../../../../services/erp-resource-api.service";

const PageConfig = (props: any) => {

    const {isSuccess, data} = useSafeQuery(useGetQuery, {doc: props.doctype, name: props.name})

    return (
        <>
        </>
    )
}

export default PageConfig