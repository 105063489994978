import UiElementContainer from "../../library/ui-element-container/ui-element-container.component";

const PageGenerator = ({configs}: any) => {

    const markup = configs && (
        configs.map((config: any, index: any) => {
            return (<UiElementContainer {...config} key={index}/>)
        })
    )

    return (
        <>
            {markup}
        </>
    )
}

export default PageGenerator