import {Text} from "@fluentui/react";

const WelcomeAlert = () => {

    return (
        <>
            <div className={'d-flex justify-content-center pt-5'}>
                <img height={'200'} src={''}/>
            </div>
            <div className={'d-flex justify-content-center pt-5'}>
                <Text variant={"xLarge"}>Welcome to DLAD ERP</Text>
            </div>
            <div className={'d-flex justify-content-center pt-1'}>
                <Text variant={"medium"}></Text>
            </div>
        </>
    )
}

export default WelcomeAlert