import {useExecMethodApiQuery} from "../../../../../../services/erp-method-api.service";
import useSafeQuery from "../../../../../hooks/use-safe-query.hook";
import {EXEC_QUERY_METHOD} from "../../../../../../store/consts/erp-method.consts";
import GeneralTable from "../general-table/general-table.components";
import useQueryParams from "../../../../../hooks/use-query-params.hook";

const CompactExternalDataList = ({config}: any) => {

    const id = useQueryParams('id');

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD,
        body: {name: config.query, id: id}
    })

    let model = (data && data.message) ? data.message.map((line: any, index: number) => ({
        key: index.toString(),
        ...line
    })) : []

    return (
        <>
            <GeneralTable config={config} model={model}/>
        </>
    )

}

export default CompactExternalDataList