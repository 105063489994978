import {Shimmer, ShimmerElementsGroup} from "@fluentui/react";

const wrapperStyle = {display: 'flex'};

const NumberCardTextShimmer = () => {
    const getCustomElement = (): JSX.Element => {
        return (
            <div style={wrapperStyle}>
                <ShimmerElementsGroup
                    width={'100px'}
                />
            </div>
        );
    };

    return <Shimmer className={"m-2"} customElementsGroup={getCustomElement()}/>
}

export default NumberCardTextShimmer