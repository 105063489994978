import {erpResourceApi} from "../../../../../../services/erp-resource-api.service";
import * as messageAction from "../../../../../../store/reducers/general-ui/message.store";
import {MessageBarType} from "@fluentui/react";
import {pathGenerator} from "../../../../../../store/actions/system/system.actions";

export const createSiteVisitAction = (model: any, clearFormFields: any, navigate: any) => {
    return async (dispatch: any) => {
        dispatch(messageAction.show({title: 'Processing.....', type: MessageBarType.info}))

        // let isValid = await leadSchema.isValid(model);
        if (false) {
            dispatch(messageAction.show({title: 'Invalid', type: MessageBarType.error}))

        } else {
            const {error, data} = await dispatch(erpResourceApi.endpoints.create.initiate({
                doc: 'DLAD Site Visit',
                payload: model
            }))
            console.log(data)
            if (error) {
                dispatch(messageAction.show({title: 'Error', type: MessageBarType.error}))
            }

            if (data) {
                let to = '/app/page/' + pathGenerator('Site Visit', 'view') + '?id=' + data?.data.name
                navigate(to)
                dispatch(messageAction.show({title: 'Success [Created New Site Visit]', type: MessageBarType.success}))
                clearFormFields()
            }
        }
    }
}