import MainUiLayout from "../layout/main-ui-layout/main-ui.layout";
import DefaultScrollLayout from "../layout/default-scroll-layout/default-scroll.layout";
import DefaultVerticalStackLayout from "../layout/default-vertical-stack-layout/default-vertical-stack.layout";
import DefaultHorizontalStackLayout from "../layout/default-horizontal-stack-layout/default-horizontal-stack.layout";
import DefaultRowLayout from "../layout/default-row-layout/default-row.layout";
import DefaultColLayout from "../layout/default-col-layout/default-col.layout";
import DefaultFormLayout from "../layout/default-form-layout/default-form.layout";

const LAYOUT_CONFIG: any = [
    {
        componentId: "main",
        layout: MainUiLayout
    },
    {
        componentId: "scroll view",
        layout: DefaultScrollLayout
    },
    {
        componentId: "vertical stack",
        layout: DefaultVerticalStackLayout
    },
    {
        componentId: "horizontal stack",
        layout: DefaultHorizontalStackLayout
    },
    {
        componentId: "row",
        layout: DefaultRowLayout
    },
    {
        componentId: "col",
        layout: DefaultColLayout
    },
    // {
    //     componentId: "masonry",
    //     layout: MasonryLayout
    // },
    {
        componentId: "form",
        layout: DefaultFormLayout
    }
]
export default LAYOUT_CONFIG