import useSafeQuery from "../../../../../hooks/use-safe-query.hook";
import {useListQuery} from "../../../../../../services/erp-resource-api.service";
import GeneralTable from "../general-table/general-table.components";
import useQueryParams from "../../../../../hooks/use-query-params.hook";
import _ from 'lodash'

const CompactStandardDataTable = ({config}: any) => {

    const id = useQueryParams('id');

    const compiled = _.template(config.filters);
    const filters = compiled({id});


    const {data} = useSafeQuery(useListQuery, {
        method: config.data_doc,
        fields: config.header_fields.map((line: any) => (line.key)),
        filter: filters,
        fetchCount: 10000
    })

    let model = (data && data.data) ? data.data.map((line: any, index: number) => ({
        key: index.toString(),
        ...line
    })) : []


    return (<GeneralTable config={config} model={model}/>)
}

export default CompactStandardDataTable