import {DATE, PICKER, SELECT, TEXT} from "./editor-types";
import InlineTextEditor from "./text/inline-text-editor";
import InlineSelectEditor from "./select/inline-select-editor";
import {useDispatch} from "react-redux";
import {KeyCodes} from "@fluentui/react";
import {setValue} from "../../../../store/reducers/page/doc/doc-page.store";
import InlineDateEditor from "./date/inline-date-editor";
import InlinePickerComponent from "./picker/inline-picker.component";

const InlineEditor = (props: any) => {

    const dispatch = useDispatch()

    const onKeyDown = (event: any) => {
        if (event.keyCode == KeyCodes.enter) {
            props.setEditMode(false)
        }
    }

    const onChange = (event: any, value: any) => {
        let newValue = '';

        if (typeof value == 'object')
            newValue = value.key
        else newValue = value

        dispatch(setValue({path: props.path, value: newValue}))
    }

    let markup = <></>

    switch (props.editType) {
        case TEXT: {
            markup = (<InlineTextEditor {...props} onChange={onChange} onKeyDown={onKeyDown}/>)
            break;
        }
        case SELECT: {
            markup = (<InlineSelectEditor {...props} onChange={onChange} onKeyDown={onKeyDown}/>)
            break;
        }
        case DATE: {
            markup = (<InlineDateEditor {...props} onChange={onChange} onKeyDown={onKeyDown}/>)
            break;
        }
        case PICKER: {
            markup = (<InlinePickerComponent {...props} onChange={onChange} onKeyDown={onKeyDown}/>)
            break;
        }
    }

    return markup
}

export default InlineEditor