import COMPONENT_CONFIG from "./component.config";
import LAYOUT_CONFIG from "./layout.config";

const ComponentFactory = (type: String, componentId: String) => {
    if (type == "structural") return getLayout(componentId)
    if (type == "functional") return getComponent(componentId)
}

const getComponent = (componentId: String) => {
    return COMPONENT_CONFIG.find((item: any) => item.componentId == componentId)
}

const getLayout = (componentId: String) => {
    return LAYOUT_CONFIG.find((item: any) => item.componentId == componentId)
}

export default ComponentFactory