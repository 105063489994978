import {
    Dropdown,
    FontIcon,
    IComboBoxOption,
    IComboBoxStyles,
    KeyCodes,
    Spinner, SpinnerSize,
    VirtualizedComboBox
} from "@fluentui/react";
import useSafeQuery from "../../../../hooks/use-safe-query.hook";
import {useExecMethodApiQuery} from "../../../../../services/erp-method-api.service";

// const comboBoxStyles: Partial<IComboBoxStyles> = {root: {maxWidth: '250'}};

const InlinePicker = ({model, editDataQuery, path, setEditMode, onChange, onKeyDown}: any) => {

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: 'dlad_sap_connector.dlad_sap_connector.api.execute_query',
        body: {name: editDataQuery}
    })

    const onChangeHandler = (e: any, value: any) => {
        onChange(e, value);
        onKeyDown({keyCode: KeyCodes.enter})
    }

    const markup = data ? (
            <div className={'d-flex align-items-center'}>
                <VirtualizedComboBox
                    allowFreeform
                    autoComplete="on"
                    options={data?.message ?? []}
                    useComboBoxAsMenuWidth
                    onKeyDown={onKeyDown}
                    // styles={comboBoxStyles}
                    onChange={onChangeHandler}
                />
                <FontIcon className={'ps-2'} iconName={'Cancel'} onClick={() => onKeyDown({keyCode: KeyCodes.enter})}/>
            </div>

        ) :
        <Spinner size={SpinnerSize.xSmall}/>

    return markup
}

export default InlinePicker