import {useDispatch, useSelector} from "react-redux";
import {setVisibility} from "../../../store/reducers/apps/admin/panel.store";
import {FontIcon} from "@fluentui/react";

const EditButtonComponent = ({config}: any) => {

    const dispatch = useDispatch()
    const {isEditable} = useSelector((state: any) => state.privileges)

    const onSettingsClickHandler = () => {
        dispatch(setVisibility({visible: true, config}))
    }

    const markup = isEditable && <FontIcon aria-label="Settings" iconName="Settings"
                                           style={{fontSize: 12, cursor: 'pointer', color: '#2b579a'}}
                                           onClick={onSettingsClickHandler}
                                           className={'pe-2'}/>

    return (
        <>
            {markup}
        </>
    )

}

export default EditButtonComponent