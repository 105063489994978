import {Outlet} from "react-router";
import Sidebar from "../../../common/sidebar/sidebar.component";
import Header from "../../../common/header/header.component";
import InternalMainContainer from "../../components/internal-main-container/internal-main.container";
import RootUi from "../../components/root-ui/root-ui.component";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {configureSystemSettings} from "../../../../store/actions/system/system.actions";

const DefaultInternalLayout = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(configureSystemSettings())
    }, [])

    const sideBarMarkup = (<Sidebar/>)

    return (
        <RootUi>
            <Header/>
            <InternalMainContainer sidebar={sideBarMarkup}>
                <Outlet/>
            </InternalMainContainer>
        </RootUi>
    );
};

export default DefaultInternalLayout;
