import {useDispatch} from "react-redux";
import useInput from "../../../../../hooks/use-input.hook";
import {CommandBar, DocumentCardDetails, Dropdown, Pivot, PivotItem, Stack, TextField} from "@fluentui/react";
import AutoCompleteTextField
    from "../../../../../common/form-components/auto-compolete-text-field/auto-complete-text-field";
import {createTaskAction} from "../actions/create-tasks.actions";
import RelationalField from "../../../../../common/form-components/relational-fields/relational-field.component";
import DateTimePicker from "../../../../../common/form-components/data-time-picker/date-time-picker.component";
import React from "react";
import {useSearchParams} from "react-router-dom";

const CreateTasksPage = () => {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [form, formHandler, formValueHandler, clearAll] = useInput()

    const dropDownHandler = (e: any, option: any, index: any) => {
        formValueHandler(e.target.id, option.text)
    }

    const relationalFieldHandler = ({key, text}: any) => {
        formValueHandler(key, text)
    }

    const createTask = async () => {
        let relatedDocs = (searchParams.get('ref') && searchParams.get('refDoc')) ? {
                related_document_type: searchParams.get('refDoc'),
                related_document_id: searchParams.get('ref')
            }
            :
            {}

        let model = {
            ...relatedDocs,
            ...form,
        }
        dispatch(createTaskAction(model, clearAll))
    }

    const ExtendMarkup: any = extendFactory(form['type'])

    const commandBarMarkup =
        (<div className={'mx-2 mb-2'}>
                <CommandBar
                    items={[{
                        key: 'save',
                        text: 'Save',
                        iconProps: {iconName: 'Save'},
                        onClick: () => {
                            createTask()
                        },
                        ['data-automation-id']: 'newSaveButton', // optional
                    }]}
                    // farItems={_farItems}
                    styles={{
                        root: {
                            padding: 0,
                        },
                    }}
                    overflowButtonProps={{ariaLabel: "More commands"}}
                    ariaLabel="Inbox actions"
                    primaryGroupAriaLabel="Email actions"
                    farItemsGroupAriaLabel="More actions"
                />
            </div>
        )

    const generalInformationMarkup =
        (
            <Stack className="pb-1 px-2">
                <DocumentCardDetails className={'px-2 bg-white rounded-2'}>
                    <div className={'row py-2'}>
                        <div className={'col-lg-4'}>
                            <AutoCompleteTextField keyValuePair={{key: 'description', text: 'description'}}
                                                   label={"Type"}
                                                   id={'type'} onChange={formValueHandler}
                                                   options={{type: "Standard", query: "DLAD Activity Type"}}
                                                   required
                            />
                            <AutoCompleteTextField keyValuePair={{key: 'description', text: 'description'}}
                                                   label={"Subtype"}
                                                   id={'subtype'} onChange={formValueHandler}
                                                   options={{
                                                       type: "Standard",
                                                       query: "DLAD Activity Sub Type",
                                                       filters: [["DLAD Activity Sub Type", "activity", "=", form["type"]]]
                                                   }}/>
                            <ExtendMarkup dropDownHandler={dropDownHandler}/>
                            <Dropdown
                                placeholder="Subject"
                                label="Subject"
                                id={'subject'}
                                onChange={dropDownHandler}
                                options={[
                                    {key: 'New', text: '0-New'}
                                ]}
                                required
                            />
                            <AutoCompleteTextField label={"Assigned To"} id={'assigned_to'} onChange={formValueHandler}
                                                   options={{type: "erp", query: {name: 'd9cdbfabe5'}}}/>

                        </div>
                        <div className={'col-lg-4'}>
                            <RelationalField
                                required
                                name={{
                                    main: {key: 'related_to', label: 'Relate To'},
                                    sub: {key: 'contact_person', label: 'Contact Person'}
                                }}
                                onChangeMain={relationalFieldHandler}
                                onChangeSub={relationalFieldHandler}
                                options={{
                                    main: {
                                        query: '3df621b418'
                                    }
                                }}
                                docConfig={[
                                    {
                                        label: 'Business Partners',
                                        doc: "Business Partners",
                                        type: "erp",
                                        query: '3df621b418'
                                    },
                                    {
                                        label: 'Leads',
                                        doc: "Leads",
                                        type: "Standard",
                                        query: 'DLAD Site Visit'
                                    }
                                ]}
                            />
                            <TextField value={form['phone']} onChange={formHandler} name={'phone'}
                                       label="Phone"
                            />
                            <Dropdown
                                placeholder="Priority"
                                label="Priority"
                                id={'Priority'}
                                onChange={dropDownHandler}
                                options={[
                                    {key: 'High', text: 'High'},
                                    {key: 'Normal', text: 'Normal'},
                                    {key: 'Low', text: 'Low'},
                                ]}
                                required
                            />
                            <Dropdown
                                placeholder="Status"
                                label="Status"
                                id={'status'}
                                onChange={dropDownHandler}
                                options={[
                                    {key: 'Completed', text: 'Completed'},
                                    {key: 'Not Started', text: 'Not Started'},
                                    {key: 'In Progress', text: 'In Progress'},
                                    {key: 'Waiting on Someone Else', text: 'Waiting on Someone Else'},
                                    {key: 'Deferred', text: 'Deferred'},
                                ]}
                            />
                        </div>
                        <div className="col-lg-4">
                            <DateTimePicker required label={'Start Time'} id={'start_time'}
                                            onChange={formValueHandler}/>
                            <DateTimePicker required label={'End Time'} id={'end_time'} onChange={formValueHandler}/>
                            <TextField value={form['remarks']} onChange={formHandler} name={'remarks'} label="Remarks"
                                       multiline
                                       rows={3}
                            />

                        </div>
                    </div>
                </DocumentCardDetails>
            </Stack>
        )

    const relatedDocumentMarkup = (
        <Stack className="pb-1 px-2">
            <DocumentCardDetails className={'px-2 bg-white rounded-2'}>
                <div className={'row py-2'}>
                    <div className={'col-lg-4'}>
                        {
                            searchParams.get('ref') && searchParams.get('refDoc')
                                ? <>
                                    <TextField value={searchParams.get('refDoc') ?? ''} onChange={formHandler}
                                               name={'related_document_type'}
                                               disabled={true}
                                               label="Related document type"/>
                                    <TextField value={searchParams.get('ref') ?? ''} onChange={formHandler}
                                               name={'related_document_id'}
                                               disabled={true}
                                               label="Related document id"/>
                                </>
                                : <RelationalField
                                    name={{
                                        main: {key: 'related_document_type', label: 'Related document type'},
                                        sub: {key: 'related_document_id', label: 'Related document id'}
                                    }}
                                    onChangeMain={relationalFieldHandler}
                                    onChangeSub={relationalFieldHandler}
                                    options={{
                                        main: {
                                            query: '3df621b418'
                                        }
                                    }}
                                    docConfig={[
                                        {
                                            label: "DLAD Site Visit",
                                            doc: "DLAD Site Visit",
                                            type: "Standard",
                                            query: 'DLAD Site Visit'
                                        }
                                    ]}
                                />
                        }
                    </div>
                </div>
            </DocumentCardDetails>
        </Stack>
    )

    const tabMarkup = (
        <Pivot
            overflowBehavior={'menu'}
            aria-label="Basic Pivot Example">

            <PivotItem
                headerText="General Information"
                headerButtonProps={{
                    'data-order': 1,
                    'data-title': 'My Files Title',
                }}
            >
                {generalInformationMarkup}
            </PivotItem>
            <PivotItem headerText="Related Document">
                {relatedDocumentMarkup}
            </PivotItem>

        </Pivot>
    )

    return (
        <>
            {commandBarMarkup}
            {tabMarkup}
        </>
    )
}

const extendFactory = (type: String) => {
    switch (type) {
        case 'Event':
            return EventExtends
            break;
        case 'Case':
            return CaseExtends
            break;
        default :
            return NullComponent
    }
}

const NullComponent = (props: any) => {
    return (<></>)
}

const CaseExtends = ({dropDownHandler}: any) => {

    return (
        <>
            <Dropdown
                placeholder="Case Origin"
                label="Case Origin"
                id={'case_origin'}
                onChange={dropDownHandler}
                options={[
                    {key: 'Call', text: 'Call'},
                    {key: 'Email', text: 'Email'},
                    {key: 'Web Site', text: 'Web Site'},
                    {key: 'Visit', text: 'Visit'},
                    {key: 'Social Media', text: 'Social Media'},
                ]}
            />
        </>
    )
}

const EventExtends = ({dropDownHandler}: any) => {

    return (
        <>
            <Dropdown
                placeholder="Personal"
                label="Personal"
                id={'personal'}
                onChange={dropDownHandler}
                options={[
                    {key: 'yes', text: 'Yes'},
                    {key: 'no', text: 'No'},
                ]}
            />
        </>
    )
}

export default CreateTasksPage