import {Stack} from "@fluentui/react";
import {useDispatch} from "react-redux";
import useQueryParams from "../../../../../hooks/use-query-params.hook";
import {setDocAction} from "../../../../../../store/actions/pages/doc/doc-page.actions";
import {useExecMethodApiQuery} from "../../../../../../services/erp-method-api.service";
import {EXEC_QUERY_METHOD} from "../../../../../../store/consts/erp-method.consts";
import useSafeQuery from "../../../../../hooks/use-safe-query.hook";
import PageGenerator from "../../../../../common/page-generator/page-generator.component";

const ErpDocPage = (props: any) => {

    const dispatch = useDispatch()
    const id = useQueryParams('id');

    const {data, isSuccess} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: props.base_query,
            id: id
        }
    })

    if (data && isSuccess) {
        setTimeout(() => {
            dispatch(setDocAction(data.message))
        }, 1)
    }

    return (
        <Stack>
            <PageGenerator configs={props.layout.configs}/>
            {/*{JSON.stringify(props)}*/}
        </Stack>
    )
}

export default ErpDocPage