import {createSlice} from '@reduxjs/toolkit'

interface IModalState {
    visible: boolean
    model: any
}

const initialState = {
    visible: false,
    model: {}
} as IModalState

const slice = createSlice({
    name: 'alert-state-store',
    initialState,
    reducers: {
        show(state, {payload}) {
            state.visible = true
            state.model = payload
        },
        hide(state) {
            state.visible = false
        }
    },
})

export const {show, hide} = slice.actions
export default slice.reducer