import {createSlice} from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
    model: {}
}

const slice: any = createSlice({
    name: 'doc-page-slice',
    initialState,
    reducers: {
        setModel(state, {payload}) {
            state.model = payload
        },
        setValue(state, {payload}) {
            _.set(state.model, payload.path, payload.value)
        }
    },
})

export const {setModel, setValue} = slice.actions
export default slice.reducer