import {setModel} from "../../../reducers/page/doc/doc-page.store";

const setDocAction = (model: any) => {
    return async (dispatch: any) => {
        dispatch(setModel(model))
    }
}

export {
    setDocAction
}