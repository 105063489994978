import {Panel, PanelType, Spinner} from "@fluentui/react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/store";
import {setVisibility} from "../../../../store/reducers/apps/admin/panel.store";
import NumberCardConfig from "../components/number-card-config/number-card-config.component";
import QueryConfig from "../components/query-config/query-config.component";
import PageConfig from "../components/page-config/page-config.component";
import TupleCardContainerConfigComponent
    from "../components/tuple-card-container-config/tuple-card-container-config.component";
import NumberCardListConfig from "../components/number-card-list-config/number-card-list-config.component";
import TableConfig from "../components/table-config/table-config.component";

const ConfigEditPanel = () => {

    const dispatch = useDispatch()
    const {visible, config} = useSelector<RootStore, any>((state) => state.apps.adminPanel)

    let markup = (<Spinner/>)

    switch (config.doctype) {
        case 'DLAD C Number Card': {
            markup = <NumberCardConfig {...config}/>
            break;
        }

        case 'DLAD Query': {
            markup = <QueryConfig {...config}/>
            break;
        }

        case 'DLAD Page': {
            markup = <PageConfig {...config}/>
            break;
        }

        case 'DLAD C Tuple Card Container' : {
            markup = (<TupleCardContainerConfigComponent {...config}/>)
            break
        }
        
        case 'DLAD C Dashboard': {
            markup = <NumberCardListConfig {...config}/>
            break;
        }

        case 'DLAD C Table': {
            markup = <TableConfig {...config}/>
            break;
        }
    }

    return (
        <Panel
            isOpen={visible}
            type={PanelType.smallFluid}
            onDismiss={() => dispatch(setVisibility({visible: false, config: {}}))}
            closeButtonAriaLabel="Close"
            headerText={config.doctype}
        >
            {markup}
        </Panel>
    )
}

export default ConfigEditPanel