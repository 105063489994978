import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    visible: false,
    target: '',
    model: {}
}

const slice = createSlice({
    name: 'info-bubble-store',
    initialState,
    reducers: {
        show(state, {payload}) {
            state.visible = true
            state.target = payload.target
            state.model = payload
        },
        hide(state) {
            state.visible = false
            state.target = ''
            state.model = {}
        }
    },
})

export const {show, hide} = slice.actions
export default slice.reducer