import _uiStateStore from './general-ui.store';
import _alertStateStore from './alert.store';
import _sidebarStore from './internal-layout/sidebar.store'
import _messageStore from './message.store'
import _infoBubble from './info-bubble.store'

import {combineReducers} from "redux";

const reducers = combineReducers({
    ui: _uiStateStore,
    alert: _alertStateStore,
    infoBubble: _infoBubble,
    message: _messageStore,
    sidebar: _sidebarStore
})

export default reducers