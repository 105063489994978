import {createSlice} from '@reduxjs/toolkit'

interface IModalState {
    isLoading: boolean
}

const initialState = {
    isLoading: false
} as IModalState

const slice = createSlice({
    name: 'login-page-slice',
    initialState,
    reducers: {
        showLoading(state) {
            state.isLoading = true
        },
        hideLoading(state) {
            state.isLoading = false
        }
    },
})

export const {showLoading, hideLoading} = slice.actions
export default slice.reducer