import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    isEditable: true
}

const slice: any = createSlice({
    name: 'admin-privileges',
    initialState,
    reducers: {
        setEditable(state) {
            state.isEditable = !state.isEditable
        },
    },
})

export const {setEditable} = slice.actions
export default slice.reducer