import {Stack} from "@fluentui/react";
import React from "react";
import {ModuleSidebar} from "./components/module-sidebar/module-sidebar.component";
import MainSidebarNavigation from "./components/main-sidebar-navigation/main-sidebar-navigation.component";

const Sidebar = () => {
    return (
        <Stack
            horizontal
            id="sidebarMenu"
            className="d-md-block bg-surface sidebar collapse"
        >
            <div className="h-100 d-flex">
                <ModuleSidebar/>
                <MainSidebarNavigation/>
            </div>
        </Stack>
    );
};

export default Sidebar;
