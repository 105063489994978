import {IButtonProps, TeachingBubble} from "@fluentui/react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/store";
import {hide} from "../../../store/reducers/general-ui/info-bubble.store";

const InfoBubble = () => {

    const dispatch = useDispatch()
    const {target, visible}: any = useSelector<RootStore, any>((state) => state.uiStore.infoBubble)
    const examplePrimaryButtonProps: IButtonProps = {
        children: 'Close',
    };

    const hideActionHandler = () => {
        dispatch(hide())
    }

    return (
        visible ? (
                <TeachingBubble
                    target={`#${target}`}
                    primaryButtonProps={examplePrimaryButtonProps}
                    onDismiss={hideActionHandler}
                    hasCloseButton={true}
                    footerContent={target}
                    headline="this is the title"
                >
                    Lorn?
                </TeachingBubble>
            )
            : <></>
    )
}

export default InfoBubble