import {createSlice} from '@reduxjs/toolkit'

interface IModalState {
    modules: any
}

const initialState = {
    modules: null
} as IModalState

const slice = createSlice({
    name: 'side-bar-store',
    initialState,
    reducers: {
        setModules(state, {payload}: any) {
            state.modules = payload.message
        }
    },
})

export const {setModules} = slice.actions
export default slice.reducer