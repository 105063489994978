import * as yup from 'yup';

let generalSchema = yup.object().shape({
    type: yup.string().required(),
    start_time: yup.string().required(),
    end_time: yup.string().required(),
    priority: yup.string().required(),
    related_to: yup.string().required(),
    contact_person: yup.string().required()
});

let eventSchema = yup.object().shape({
    ...generalSchema.shape,
    personal: yup.string().required(),
});

let caseSchema = yup.object().shape({
    ...generalSchema.shape,
    case_origin: yup.string().required(),
});

export {eventSchema, caseSchema, generalSchema}