import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    locations: [{lat: '-37.73289395276815', long: '144.8350208117412'}]
}

const slice = createSlice({
    name: 'google-map-app-slice',
    initialState,
    reducers: {
        setLocations(state, {payload}: any) {
            state.locations = payload
        }
    },
})

export const {setLocations} = slice.actions
export default slice.reducer