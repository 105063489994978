import {getFocusStyle, getTheme, ITheme, List, mergeStyleSets, SearchBox, Spinner} from "@fluentui/react";
import useUiConfigs from "../../../hooks/use-ui-config.hook";
import useAutoFit from "../../../hooks/use-auto-fit.hook";
import {useEffect} from "react";
import useSafeQuery from "../../../hooks/use-safe-query.hook";
import {useExecMethodApiQuery} from "../../../../services/erp-method-api.service";
import {EXEC_QUERY_METHOD} from "../../../../store/consts/erp-method.consts";
import {useNavigate} from "react-router-dom";
import BasicStandardList from "./basic-standard-list/basic-list.component";
import BasicErpList from "./basic-erp-list/basic-erp-list.component";

const theme: ITheme = getTheme();
const {palette, semanticColors, fonts} = theme;

const classNames = mergeStyleSets({
    container: {
        overflow: "auto",
        // maxHeight: 500,
    },
    itemCell: [
        getFocusStyle(theme, {inset: -1}),
        {
            minHeight: 54,
            padding: 10,
            boxSizing: "border-box",
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: "flex",
            selectors: {
                "&:hover": {background: palette.neutralLight},
            },
        },
    ],
    itemImage: {
        flexShrink: 0,
    },
    itemContent: {
        marginLeft: 10,
        overflow: "hidden",
        flexGrow: 1,
    },
    itemName: [
        fonts.medium,
        ,
        {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    ],
    itemIndex: {
        fontSize: fonts.small.fontSize,
        color: palette.neutralTertiary,
        marginBottom: 10,
    },
    chevron: {
        alignSelf: "center",
        marginLeft: 10,
        color: palette.neutralTertiary,
        fontSize: fonts.large.fontSize,
        flexShrink: 0,
    },
});

const DefaultList = ({type, id}: any) => {
    const [ref, setAutoFit] = useAutoFit()

    const {data} = useUiConfigs(type, id)

    const _onChangeText = (ev?: React.ChangeEvent<HTMLInputElement | undefined>, text?: any): void => {
        // let list = text ? items.filter((i: any) => i.name.toLowerCase().indexOf(text) > -1) : items
        // setList(list)
    };

    useEffect(() => {
        setAutoFit()
    })

    let markup = (<Spinner/>);

    if (data) {
        switch (data.doc_page_type) {
            case 'erp': {
                markup = (<BasicErpList {...data}/>)
                break;
            }
            case 'standard': {
                markup = (<BasicStandardList {...data}/>)
                break;
            }
        }
    }

    return (
        <div className={'bg-white rounded-2 m-2 pt-1'}>
            <SearchBox onChange={_onChangeText} className="m-2 rounded-2" placeholder="Search"/>
            <div className={'bg-white rounded-2'}>
                <div ref={ref} className="overflow-auto">
                    {markup}
                </div>
            </div>
        </div>
    )
}

export default DefaultList