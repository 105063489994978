import {useSelector} from "react-redux";
import {RootStore} from "../../../store/store";
import {
    ICommandBarStyleProps,
    ICommandBarStyles,
    IPartialTheme,
    ITextFieldStyleProps, ITextFieldStyles,
    ThemeProvider
} from "@fluentui/react";
import {AzureThemeDark, AzureThemeLight} from "@fluentui/azure-themes";
import {IExtendedSemanticColors} from "@fluentui/azure-themes/lib/azure/IExtendedSemanticColors";

const ThemeManager = ({children}: any) => {

    const {theme} = useSelector<RootStore, any>((state) => state.uiStore.ui)

    let styles: IPartialTheme = {}

    switch (theme) {
        case '365': {
            styles = {}
            break;
        }
        case 'azure-light': {
            styles = AzureThemeLight
            break;
        }

        case 'azure-dark': {
            styles = AzureThemeDark
            break;
        }

    }

    const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 480px)").matches

    let appliedTheme: any = {}
    if (!isMobile())
        appliedTheme.components = {
            TextField: AzureThemeLight.components?.TextField,
            ComboBox: AzureThemeLight.components?.ComboBox,
            Dropdown: AzureThemeLight.components?.Dropdown,
            CommandBar: AzureThemeLight.components?.CommandBar
        }

    appliedTheme.components.CommandBar.styles = CommandBarStyles

    return (
        <ThemeProvider theme={appliedTheme}>
            {children}
        </ThemeProvider>
    )
}

const CommandBarStyles = (props: ICommandBarStyleProps): Partial<ICommandBarStyles> => {
    const { theme } = props;
    const { semanticColors } = theme;
    const extendedSemanticColors = semanticColors as IExtendedSemanticColors;

    return {
        root: [
            {
                backgroundColor: semanticColors.bodyBackground,
                height: '36px',
                borderRadius: '0.25rem',
                borderBottomColor: extendedSemanticColors.commandBarBorder,
                paddingLeft: '0.25rem',
            },
        ],
    };
};

export const TextFieldStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
    const { focused, disabled, hasErrorMessage, multiline, theme } = props;
    const { semanticColors } = theme;
    const extendedSemanticColors = semanticColors as IExtendedSemanticColors;

    return {
        fieldGroup: [
            !multiline && {
                height: '40px',
            },
            !hasErrorMessage && {
                borderColor: semanticColors.inputBorder,
                selectors: {
                    '::after': {
                        borderColor: extendedSemanticColors.controlFocus,
                    },
                },
            },
            !focused &&
            !disabled && {
                selectors: {
                    ':hover': {
                        borderColor: semanticColors.inputText,
                    },
                },
            },
            focused && {
                borderColor: semanticColors.focusBorder,
            },
            disabled && {
                borderColor: extendedSemanticColors.textFieldBorderDisabled,
                backgroundColor: semanticColors.primaryButtonBackgroundDisabled,
            },
            hasErrorMessage && [
                {
                    borderWidth: '1px',
                },
                focused && {
                    borderColor: semanticColors.focusBorder,
                    selectors: {
                        '&:focus, &:hover': {
                            borderColor: semanticColors.focusBorder,
                        },
                    },
                },
            ],
        ],
        icon: {
            bottom: 2,
        },
        prefix: {
            fontSize: theme.fonts.medium.fontSize,
        },
        suffix: {
            fontSize: theme.fonts.medium.fontSize,
        },
        field: [
            {
                color: semanticColors.inputText,
                backgroundColor: extendedSemanticColors.controlBackground,
                fontSize: theme.fonts.medium.fontSize,
                selectors: {
                    '::placeholder': {
                        color: semanticColors.inputPlaceholderText,
                        fontStyle: 'italic',
                    },
                    ':-ms-input-placeholder': {
                        color: semanticColors.inputPlaceholderText,
                        fontStyle: 'italic',
                    },
                    '::-webkit-input-placeholder': {
                        color: semanticColors.inputPlaceholderText,
                        fontStyle: 'italic',
                    },
                },
            },
            disabled && {
                color: semanticColors.primaryButtonTextDisabled,
                backgroundColor: semanticColors.primaryButtonBackgroundDisabled,
                selectors: {
                    '::placeholder': {
                        color: semanticColors.disabledBodyText,
                    },
                    ':-ms-input-placeholder': {
                        color: semanticColors.disabledBodyText,
                    },
                    '::-webkit-input-placeholder': {
                        color: semanticColors.disabledBodyText,
                    },
                },
            },
        ],
        errorMessage: {
            color: semanticColors.errorText,
        },
    };
};



export default ThemeManager