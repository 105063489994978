import erpResourceApi from "../../../services/erp-resource-api.service";
import * as alertActions from "../../reducers/general-ui/alert.store";

const configureSystemSettings = () => {
    return async (dispatch: any) => {

        const {error, data} = await dispatch(erpResourceApi.endpoints.get.initiate({
            doc: 'DLAD System Settings',
            name: 'DLAD System Settings'
        }))

        if (!error) setToLocalStorage(data)
        else dispatch(alertActions.show({title: 'invalid', subtitle: ""}))
    }
}

const pathGenerator = (name: string, type: string) => {
    let static_paths = JSON.parse(localStorage.getItem('static_pages') ?? '')
    let line = static_paths && static_paths.find((line: any) => (line.label == name && line.type == type))
    return line?.path
}

const setToLocalStorage = (data: any) => {
    localStorage.setItem('static_pages', JSON.stringify(data?.data.static_pages ?? []))
}

export {
    configureSystemSettings,
    pathGenerator
}