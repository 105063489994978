import {useRef} from "react";

const useAutoFit = () => {
    let ref: any = useRef(null)

    const setAutoFit = () => {
        setTimeout(() => {
            ref.current && (ref.current.style.height = `calc(100vh - ${ref.current.offsetTop}px)`)
        }, 400)
    }

    return [ref, setAutoFit]
}

export default useAutoFit