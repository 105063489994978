import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {getPageConfigAction} from "../../../store/actions/general-ui/general-ui.actions";
import FactoryPage from "./components/factory.page";
import MessageBarComponent from "../../common/message-bar/message-bar.component";

const GeneralContainerPage = () => {

    const dispatch = useDispatch()
    let {name}: any = useParams()

    dispatch(getPageConfigAction({name: name}))

    return (
        <>
            <FactoryPage/>
            <div className={'position-sticky bottom-0'}>
                <MessageBarComponent/>
            </div>
        </>
    )
}

export default GeneralContainerPage