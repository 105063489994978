import {Stack} from "@fluentui/react";

const DefaultVerticalStackLayout = ({children}: any) => {

    return (
        <>
            <Stack>
                {children}
            </Stack>
        </>
    )
}

export default DefaultVerticalStackLayout