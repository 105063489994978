const InternalMainContainer = ({children, sidebar}: any) => {
    return (
        <div className="main-container container-fluid g-0 d-flex bg-surface">
            {sidebar}
            <main className="overflow-hidden w-100">{children}</main>
        </div>
    );
};

export default InternalMainContainer;
