import {useNavigate} from "react-router-dom";
import {INavStyles, Nav, Spinner, SpinnerSize} from "@fluentui/react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/store";
import {getWorkspaceAction} from "../../../../../store/actions/general-ui/general-ui.actions";

const navStyles: Partial<INavStyles> = {
    root: {
        boxSizing: "border-box",
        overflowY: "auto",
    },

    chevronButton: {
        borderBottomStyle: "none",
        fontSize: "1rem",
        fontWeight: "bold",
    },
    groupContent: {
        marginBottom: "none"
    }
};

const MainSidebarNavigation = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    let navsLinks = [];

    const {currentSelectedWorkspace} = useSelector<RootStore, any>((state) => state.uiStore.ui)
    const {workspace} = useSelector<RootStore, any>((state) => state.uiStore.ui)

    if (currentSelectedWorkspace) {
        dispatch(getWorkspaceAction({name: currentSelectedWorkspace}))
    }

    if (workspace) {
        navsLinks = workspace.map((nav: any, navIndex: Number) => ({
            name: nav.label,
            collapseByDefault: !nav.expanded,
            links: nav.links.map((childNav: any, index: Number) => ({
                key: navIndex + 'key' + index,
                name: childNav.label,
                url: "",
                icon: "inbox",
                onClick: () => {
                    navigate(`/app/${childNav.path}/${childNav.page_id}`)
                }
            }))
        }))
    }

    const markup = workspace ? (
            <Nav
                styles={navStyles}
                groups={navsLinks}
            />
    ) : (
        <div className={'mt-2'}>
            <Spinner size={SpinnerSize.medium} />
        </div>
    )

    return (
        <div className="__module-items border-end 100vh overflow-auto bg-surface">
            {markup}
        </div>
    );
}

export default MainSidebarNavigation