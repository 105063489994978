import {
    FontIcon,
    IButtonStyles,
    IconButton,
    IContextualMenuProps,
    mergeStyles,
    SearchBox,
    Stack
} from "@fluentui/react";
import {Text} from "@fluentui/react/lib/Text";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setTheme} from "../../../store/reducers/general-ui/general-ui.store";

const iconClass = mergeStyles({
    iconClass: {
        fontSize: 16,
        paddingRight: 10,
        paddingLeft: 10,
    },
});

const customSplitButtonStyles: IButtonStyles = {
    root: {
        // marginRight: "20px"
    },
    rootHovered: {
        backgroundColor: "#002050",
    },
    rootPressed: {
        backgroundColor: "#002050",
    },
    rootExpanded: {
        backgroundColor: "rgb(237 235 233 / 0%)",
    },
};

const Header: React.FunctionComponent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: "calendarEvent",
                text: "Open Calendar",
                iconProps: {iconName: "Calendar"},
                onClick: ev => {
                    navigate('/app/page/1220e6bf5c')
                }
            },
            {
                key: "azure-light",
                text: "Azure Light",
                iconProps: {iconName: "Calendar"},
                onClick: ev => {
                    dispatch(setTheme('azure-light'))
                }
            },
            {
                key: "azure-dark",
                text: "Azure Dark",
                iconProps: {iconName: "Calendar"},
                onClick: ev => {
                    dispatch(setTheme('azure-dark'))
                }
            },
            {
                key: "365",
                text: "365",
                iconProps: {iconName: "Calendar"},
                onClick: ev => {
                    dispatch(setTheme('365'))
                }
            },
            {
                key: "logout",
                text: "Logout",
                iconProps: {iconName: "SignOut"},
                onClick: ev => {
                    navigate('/');
                }
            },
        ],
    };

    return (
        <nav className="navbar p-0 g-0 sticky-top navbar-expand-md navbar-light bg-primary">
            <div className="container-fluid">
                <Stack className={"navbar-brand-btn"}>
                    <Stack horizontal className={"my-auto px-2 mx-auto"}>
                        <FontIcon
                            aria-label="Compass"
                            iconName="WaffleOffice365"
                            className={iconClass}
                            onClick={() => {
                                // dispatch(showSidebarPanel());
                            }}
                        />
                    </Stack>
                </Stack>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Stack horizontal className={"my-auto"} horizontalAlign={"start"}>
                                <Text
                                    className={"px-2 fs-6 text-white fw-bold"}
                                    variant={"medium"}
                                ></Text>
                            </Stack>
                        </li>
                    </ul>
                    <SearchBox
                        className="show w-25 position-absolute search-box"
                        placeholder="Search"
                    />

                    <Stack horizontal className={"navbar-brand-btn"}>
                        <Stack horizontal className={"my-auto  mx-auto"}>
                            <FontIcon
                                aria-label="Compass"
                                iconName="Ringer"
                                className={"mx-3 " + iconClass}
                            />
                        </Stack>
                    </Stack>

                    <Stack horizontal className={"navbar-brand-btn"}>
                        <Stack horizontal className={"my-auto  mx-auto"}>
                            <FontIcon
                                onClick={() => {
                                }}
                                aria-label="Compass"
                                iconName="Help"
                                className={"mx-3 " + iconClass}
                            />
                        </Stack>
                    </Stack>

                    <IconButton
                        className={"navbar-brand-btn " + iconClass}
                        styles={customSplitButtonStyles}
                        menuProps={menuProps}
                    >
                        {/*<Persona*/}
                        {/*    {...examplePersona}*/}
                        {/*    size={PersonaSize.size24}*/}
                        {/*    presence={PersonaPresence.online}*/}
                        {/*    className='p-0, m-0'*/}
                        {/*/>*/}
                    </IconButton>
                </div>
            </div>
        </nav>
    );
};

export default Header;
