import {Spinner} from "@fluentui/react";

const PageLoadingAlert = () => {

    return (
        <>
            <div className={'d-flex justify-content-center pt-5'}>
                <Spinner/>
            </div>

        </>
    )
}

export default PageLoadingAlert