import {
    CommandBar,
    DocumentCardDetails,
    Dropdown,
    Pivot,
    PivotItem,
    Stack,
    TextField
} from "@fluentui/react";
import useInput from "../../../../../hooks/use-input.hook";
import {useDispatch} from "react-redux";
import {createLeadAction} from "../actions/leads.actions";
import {useExecMethodApiQuery} from "../../../../../../services/erp-method-api.service";
import {EXEC_QUERY_METHOD} from "../../../../../../store/consts/erp-method.consts";

const CreateLeadsPage = () => {

    const dispatch = useDispatch()
    const [form, formHandler, formValueHandler, clearAll] = useInput()
    const [postalForm, postFormHandler, postFormValueHandler, postClearAll] = useInput()
    const [shippingForm, shippingFormHandler, shippingFormValueHandler, shippingClearAll] = useInput()

    const {data} = useExecMethodApiQuery({method: EXEC_QUERY_METHOD, body: {name: '1350a3ddaa'}})

    const dropDownHandler = (e: any, option: any, index: any) => {
        formValueHandler(e.target.id, option.text)
    }

    const postalDropDownHandler = (e: any, option: any, index: any) => {
        postFormValueHandler(e.target.id, option.text)
    }

    const shippingDropDownHandler = (e: any, option: any, index: any) => {
        shippingFormValueHandler(e.target.id, option.text)
    }

    const createLead = async () => {
        let model = {
            ...form,
            addresses: [postalForm, shippingForm]
        }
        dispatch(createLeadAction(model, clearAll))
    }

    const commandBarMarkup =
        (
            <div className={'mx-2 mb-2'}>
                <CommandBar
                    items={[{
                        key: 'save',
                        text: 'Save',
                        iconProps: {iconName: 'Save'},
                        onClick: () => {
                            createLead()
                        },
                        ['data-automation-id']: 'newSaveButton', // optional
                    }]}
                    overflowButtonProps={{ariaLabel: "More commands"}}
                    ariaLabel="Inbox actions"
                    primaryGroupAriaLabel="Email actions"
                    farItemsGroupAriaLabel="More actions"
                />
            </div>
        )


    const ownerMarkup =
        (<div className={"py-1 pb-2 px-2"}>
                <div className={'d-lg-flex'}>
                    <Dropdown
                        className={'me-2'}
                        placeholder="Select Owner"
                        label="Owner"
                        id={'owner'}
                        onChange={dropDownHandler}
                        options={data?.message ?? []}
                        styles={{dropdown: {width: 300}}}
                    />
                    <Dropdown
                        className={'me-2'}
                        placeholder="Lead Status"
                        label="Lead Status"
                        id={'lead_status'}
                        onChange={dropDownHandler}
                        options={[
                            {key: 'New', text: '0-New'},
                            {
                                key: 'Contact-made',
                                text: '1-Contact Made'
                            },
                            {key: 'Sampling', text: '2-sampling'},
                            {key: 'Pricing', text: '3-Pricing'},
                            {key: 'Account Application Sent', text: '4-Account Application Sent'},
                            {key: 'Account Application Received', text: '5-Account Application Received'},
                            {key: 'Converted', text: '6-Converted'},
                            {key: 'Dead', text: '99-Dead'},
                        ]}
                        styles={{dropdown: {width: 300}}}
                    />
                </div>
                <br/>
            </div>
        )

    const basicInfoMarkup =
        (<Stack className="pb-1 px-2">
                <DocumentCardDetails className={'px-2 bg-white rounded-2'}>
                    <div className={'row py-2'}>
                        <div className={'col-lg-4'}>
                            <TextField value={form['first_name']} required onChange={formHandler} name={'first_name'}
                                       label="First Name"/>
                            <TextField value={form['last_name']} onChange={formHandler} name={'last_name'}
                                       label="Last Name"/>
                            <TextField value={form['title']} onChange={formHandler} name={'title'} label="Title"/>
                            <TextField value={form['company']} onChange={formHandler} name={'company'} label="Company"
                                       required/>
                            <TextField value={form['customer_type']} onChange={formHandler} name={'customer_type'}
                                       label="Customer Type"/>
                        </div>
                        <div className={'col-lg-4'}>
                            <div className={'col-lg'}>
                                <TextField value={form['phone']} onChange={formHandler} name={'phone'} label="Phone"/>
                                <TextField value={form['mobile']} onChange={formHandler} name={'mobile'}
                                           label="Mobile"/>
                                <TextField value={form['email']} onChange={formHandler} name={'email'} label="Email"/>
                                <TextField value={form['opening_hours']} onChange={formHandler} name={'opening_hours'}
                                           label="Opening Hours"/>
                                <TextField value={form['preferred_contact']} onChange={formHandler}
                                           name={'preferred_contact'}
                                           label="Preferred Contact"/>
                            </div>
                        </div>
                    </div>
                </DocumentCardDetails>
            </Stack>
        )

    const leadStatusMarkup = (
        <Stack className="pb-1 px-2">
            <DocumentCardDetails className={'px-2 bg-white rounded-2'}>
                <div className={'row py-2'}>
                    <div className={'col-lg-4'}>
                        <Dropdown
                            placeholder="Select an Rating"
                            label="Rating"
                            id={'rating'}
                            onChange={dropDownHandler}
                            options={[{key: 'Hot', text: 'Hot'}, {key: 'Warm', text: 'Warm'}, {
                                key: 'Cold',
                                text: 'Cold'
                            }]}
                        />
                        <TextField onChange={formHandler} name={'abn'} label="ABN"/>
                    </div>
                </div>
            </DocumentCardDetails>
        </Stack>
    )

    const informationMarkup =
        (<Stack className="pb-1 px-2">
                <DocumentCardDetails className={'px-2 bg-white rounded-2'}>
                    <div className={'row py-2'}>
                        <div className={'col-lg-4'}>
                            <TextField value={form['current_tea_provider']} onChange={formHandler}
                                       name={'current_tea_provider'}
                                       label="Current Tea Provider"/>
                            <TextField value={form['current_tea_provider']} onChange={formHandler}
                                       name={'current_tea_provider'}
                                       label="Current Coffee Provider"/>
                            <TextField value={form['lead_retailwholesale']} onChange={formHandler}
                                       name={'lead_retailwholesale'}
                                       label="Lead Retail/Wholesale"/>
                            <TextField value={form['notes']} onChange={formHandler} name={'notes'} label="Notes"
                                       multiline
                                       rows={3}/>
                        </div>
                        <div className={'col-lg-4'}>
                            <div className={'col-lg'}>
                                <Dropdown
                                    placeholder="Select Lead Source"
                                    label="Lead Source"
                                    id={'lead_source'}
                                    onChange={dropDownHandler}
                                    options={[
                                        {key: 'Advertisement', text: 'Advertisement'},
                                        {key: 'Change of Distribution Channel', text: 'Change of Distribution Channel'},
                                        {key: 'Employee Referral', text: 'Employee Referral'},
                                        {key: 'External Referral', text: 'External Referral'},
                                        {key: 'Other', text: 'Other'},
                                        {key: 'Partner', text: 'Partner'},
                                        {key: 'Public Relations', text: 'Public Relations'},
                                        {key: 'Seminar - Internal', text: 'Seminar - Internal'},
                                        {key: 'Seminar - Partner', text: 'Seminar - Partner'},
                                        {key: 'Trade Show', text: 'Trade Show'},
                                        {key: 'Web - teadrop.com.au', text: 'Web - teadrop.com.au'},
                                        {key: 'Word of mouth', text: 'Word of mouth'},
                                        {key: 'Web - Research', text: 'Web - Research'},
                                    ]}
                                />
                                <Dropdown
                                    placeholder="Select Price Structure"
                                    label="Price Structure"
                                    id={'price_structure'}
                                    onChange={dropDownHandler}
                                    options={[
                                        {key: '--None--', text: '--None--'},
                                        {key: 'Wholesale Pricing', text: 'Wholesale Pricing'},
                                        {key: '5% off Retail Pricing', text: '5% off Retail Pricing'},
                                        {key: '20% off Retail Pricing', text: '20% off Retail Pricing'},
                                        {key: 'VIP (Check notes)', text: 'VIP (Check notes)'},
                                        {key: 'Customised Pricing', text: 'Customised Pricing'},
                                    ]}
                                />
                                <Dropdown
                                    placeholder="Select Sample Requirements"
                                    label="Sample Requirements"
                                    id={'sample_requirements'}
                                    onChange={dropDownHandler}
                                    options={[
                                        {key: '--None--', text: '--None--'},
                                        {key: 'Send Samples', text: 'Send Samples'},
                                        {key: 'Do Not Send Samples', text: 'Do Not Send Samples'},
                                    ]}
                                />
                                <Dropdown
                                    placeholder="Select Packing Style"
                                    label="Packing Style"
                                    id={'packing_style'}
                                    onChange={dropDownHandler}
                                    options={[
                                        {key: '--None--', text: '--None--'},
                                        {
                                            key: '1 CUP SAMPLES (Arrange Samples for Client)',
                                            text: '1 CUP SAMPLES (Arrange Samples for Client)'
                                        },
                                        {
                                            key: '2 CUP SAMPLES (Arrange Samples for Client)',
                                            text: '2 CUP SAMPLES (Arrange Samples for Client)'
                                        },
                                        {
                                            key: 'LOOSE LEAF SAMPLES. (Arrange Samples for Client)',
                                            text: 'LOOSE LEAF SAMPLES. (Arrange Samples for Client)'
                                        },
                                        {key: 'VARIETY PACK OF SAMPLES', text: 'VARIETY PACK OF SAMPLES'},
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </DocumentCardDetails>
            </Stack>
        )

    const postalAddressMarkup = (
        <Stack className="pb-1 px-2">
            <DocumentCardDetails className={'px-2 bg-white rounded-2'}>
                <div className={'row py-2'}>
                    <div className={'col-lg-4'}>
                        <div className={'col-lg'}>
                            <TextField value={form['street']} onChange={postFormHandler} name={'street'}
                                       label="Street"/>
                            <TextField value={form['city']} onChange={postFormHandler} name={'city'} label="City"/>
                            <Dropdown
                                placeholder="Select State"
                                label="State"
                                id={'state'}
                                onChange={postalDropDownHandler}
                                options={[
                                    {key: 'Victoria', text: 'Victoria'},
                                    {key: 'New South Wales', text: 'New South Wales'},
                                    {key: 'Queensland', text: 'Queensland'},
                                    {key: 'Tasmania', text: 'Tasmania'},
                                    {key: 'Western Australia', text: 'Western Australia'},
                                    {key: 'South Australia<', text: 'South Australia<'},
                                ]}
                            />
                            <TextField value={form['post_code']} onChange={postFormHandler} name={'post_code'}
                                       label="Post Code"/>
                        </div>
                    </div>
                </div>
            </DocumentCardDetails>
        </Stack>
    )

    const shippingAddressMarkup =
        (
            <Stack className="pb-1 px-2">
                <DocumentCardDetails className={'px-2 bg-white rounded-2'}>
                    <div className={'row py-2'}>
                        <div className={'col-lg-4'}>
                            <div className={'col-lg'}>
                                <TextField value={form['street']} onChange={shippingFormHandler} name={'street'}
                                           label="Street"/>
                                <TextField value={form['city']} onChange={shippingFormHandler} name={'city'}
                                           label="City"/>
                                <Dropdown
                                    placeholder="Select State"
                                    label="State"
                                    id={'state'}
                                    onChange={shippingDropDownHandler}
                                    options={[
                                        {key: 'Victoria', text: 'Victoria'},
                                        {key: 'New South Wales', text: 'New South Wales'},
                                        {key: 'Queensland', text: 'Queensland'},
                                        {key: 'Tasmania', text: 'Tasmania'},
                                        {key: 'Western Australia', text: 'Western Australia'},
                                        {key: 'South Australia<', text: 'South Australia<'},
                                    ]}
                                />
                                <TextField value={form['post_code']} onChange={shippingFormHandler} name={'post_code'}
                                           label="Post Code"/>
                            </div>
                        </div>
                    </div>
                </DocumentCardDetails>
            </Stack>
        )


    const tabMarkup = (
        <Pivot
            overflowBehavior={'menu'}
            aria-label="Basic Pivot Example">

            <PivotItem
                headerText="Basic Information"
                headerButtonProps={{
                    'data-order': 1,
                    'data-title': 'My Files Title',
                }}
            >
                {basicInfoMarkup}
            </PivotItem>
            <PivotItem headerText="Lead Status Information">
                {leadStatusMarkup}
            </PivotItem>
            <PivotItem headerText="Information">
                {informationMarkup}
            </PivotItem>
            <PivotItem headerText="Postal Address">
                {postalAddressMarkup}
            </PivotItem>
            <PivotItem headerText="Shipping Address">
                {shippingAddressMarkup}
            </PivotItem>

        </Pivot>
    )

    return (
        <>
            {commandBarMarkup}
            {ownerMarkup}
            {tabMarkup}
        </>
    )
}

export default CreateLeadsPage