import {IButtonProps, ICommandBarStyles} from "@fluentui/react";
import BasicCommandBar from "./components/basic-command-bar/basic-command-bar.component";
import {useGetQuery} from "../../../../services/erp-resource-api.service";

const overflowProps: IButtonProps = {ariaLabel: "More commands"};

const style: ICommandBarStyles = {
    root: {
        padding: 0
    },
};

const DefaultCommandBar = ({type, id}: any) => {

    const {data}: any = useGetQuery({doc: type, name: id})

    const markup = data ? (<BasicCommandBar config={data}/>) : null

    return (
        <>
            {markup}
        </>
    )
}

export default DefaultCommandBar