import {
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    IDetailsListProps,
    IDetailsRowStyles,
    ISelection,
    SearchBox,
    Selection,
    Stack,
    Text
} from "@fluentui/react";
import {useEffect, useState} from "react";
import useAutoFit from "../../../../../hooks/use-auto-fit.hook";
import {Link} from "react-router-dom";
import _ from "lodash";
import EditButtonComponent from "../../../../../common/edit-button/edit-button.component";

const GeneralTable = ({config, model}: any) => {

    let selection: ISelection = new Selection()

    // const [columns, setColumns] = useState([]) // need to set data
    const [groups, setGroups] = useState([])
    const [lines, setLines] = useState(model)
    const [ref, setAutoFit] = useAutoFit()

    useEffect(() => {
        setLines(model)
        if (!config.is_grouped) {
            // setColumnClickHandler(columns)
        } else {
            generateGroup(model)
        }
    }, [model])

    const generateGroup = (list: any) => {
        let uniqueArray = list.reduce((a: any, d: any) => {
            if (a.indexOf(_.get(d, config.group_by)) === -1) {
                a.push(_.get(d, config.group_by));
            }
            return a;
        }, []);

        let indexes = uniqueArray.map((_line: any) => ({
            key: _line,
            name: _line,
            count: list.filter((line: any) => _.get(line, config.group_by) == _line).length,
            level: 0,
            isCollapsed: true
        }))

        let groupedList: any = indexes.reduce((a: any, d: any) => {
            let startIndex = 0
            a.length > 0 && (startIndex = a[a.length - 1].startIndex + a[a.length - 1].count)
            a.push({...d, startIndex: startIndex});
            return a
        }, []);

        setGroups(groupedList)
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName);
        switch (column.dataType) {
            case 'link':
                return (<Link
                    to={`/app/page/${column.page}?id=${fieldContent}`}>{fieldContent}</Link>)
        }

        return fieldContent
    }

    const onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            // if (props.itemIndex % 2 === 0) {
            //     customStyles.root = {backgroundColor: 'red'};
            // }

            return <DetailsRow {...props} styles={customStyles}/>;
        }
        return null;
    };

    const columns = config && config.header_fields && config.header_fields.map((line: any) => ({
            key: line.name,
            name: line.label,
            fieldName: line.key,
            page: line.page,
            dataType: line.data_type,
            isResizable: true,
            minWidth: line.min_width,
            maxWidth: line.max_width,
        })
    )

    let className = "overflow-auto"

    if (config.height == 'autoFit') {
        setAutoFit()
    }

    if (config.height == "sm") {
        className = className + " h-sm"
    }

    if (config.height == "md") {
        className = className + " h-md"
    }

    const searchTextChangeHandler = (e: any, text: any) => {
        const filteredLines = text && model.length > 0 ? model.filter((i: any) => i.CardName.toLowerCase().indexOf(text) > -1) : model
        setLines(filteredLines)

        // if (text) {
        //
        //     let queryText = text;
        //     if (queryText) {
        //         let searchableColumns = columns;
        //
        //         let searchResult: any[] = [...model];
        //         searchResult.filter(
        //             (_gridData, index) => {
        //                 const BreakException = {};
        //                 try {
        //                     searchableColumns.forEach((item2: any, index2: any) => {
        //                         if (_gridData[item2.fieldName] && _gridData[item2.fieldName].toString().toLowerCase() && _gridData[item2.fieldName].toString().toLowerCase().includes(queryText.trim().toLowerCase())) {
        //                             _gridData._is_filtered_in_grid_search_ = true;
        //                             throw BreakException;
        //                         } else {
        //                             _gridData._is_filtered_in_grid_search_ = false;
        //                         }
        //                     });
        //                 } catch (e) {
        //                     if (e !== BreakException) throw e;
        //                 }
        //             }
        //         );
        //
        //         console.log('searchResult', searchResult)
        //
        //         setLines(searchResult);
        //     } else {
        //         const gridDataTmp: any[] = [...model];
        //         gridDataTmp.map((item) => item._is_filtered_in_grid_search_ = true);
        //         setLines(gridDataTmp);
        //     }
        // } else {
        //     const gridDataTmp: any[] = [...model];
        //     gridDataTmp.map((item) => item._is_filtered_in_grid_search_ = true);
        //     setLines(gridDataTmp);
        // }
    }

    return (
        <Stack className={'bg-white rounded-2'}>
            <div className="d-flex justify-content-between align-items-center">
                <Text className="ms-2 mt-1" variant={config.title_variant}>{config.title}</Text>
                <EditButtonComponent config={config}/>
            </div>

            <div className={'d-flex flex-wrap'}>
                {!config.is_grouped ?
                    <SearchBox onChange={searchTextChangeHandler} className="w-25 m-2 rounded-2"
                               placeholder="Search"/> : null}
                {/*{actions.length > 0 ? <CommandBarWrapper selection={selection} data={items} itemList={actions}/> : null}*/}
            </div>

            <div ref={ref} className={className}>
                <DetailsList
                    items={lines}
                    columns={columns}
                    groups={config.is_grouped ? groups : null!!}
                    selection={selection}
                    compact={true}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    selectionMode={config.selection_mode}
                />
            </div>
        </Stack>
    )
}

export default GeneralTable