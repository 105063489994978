import {useGetQuery} from "../../../../services/erp-resource-api.service";
import {Stack} from '@fluentui/react'
import filterFactory from "./dashboard-filters.factory";

const DashboardFilters = ({type, id}: any) => {
    const {data} = useGetQuery({doc: type, name: id})

    const filters = {}

    const markup = data ? (
        <div className={"py-1 pb-2 px-2"}>
            <Stack horizontal>
                {data.data.dropdowns.map((line: any, index: number) => {
                    let markup: any = filterFactory(line.type)
                    if (markup) return (<markup.component {...line} page={data.data.page} key={index}/>)
                })}
            </Stack>
            <br/>
        </div>
    ) : null

    return (markup)
}

export default DashboardFilters