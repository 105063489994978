import {erpResourceApi} from "../../../../services/erp-resource-api.service";
import {erpMethodApi} from "../../../../services/erp-method-api.service";

interface IKeyValuePair {
    key: string,
    text: string
}

export const getStandardDocumentList = (model: any, filters: any, keyValuePair: IKeyValuePair) => {
    return async (dispatch: any) => {
        let {data} = await dispatch(erpResourceApi.endpoints.list.initiate({
            method: model,
            fields: ["*"],
            filters: JSON.stringify(filters)
        }));
        return data?.data.map((line: any) => ({key: line[keyValuePair.key], text: line[keyValuePair.key]})) ?? []
    }
}

export const getDocumentList = (model: any, keyValuePair?: IKeyValuePair) => {
    return async (dispatch: any) => {
        let {data} = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: 'dlad_sap_connector.dlad_sap_connector.api.execute_query',
            body: model
        }));
        return data?.message ?? []
    }
}