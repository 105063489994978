import * as messageAction from "../../../../../../store/reducers/general-ui/message.store";
import {MessageBarType} from "@fluentui/react";
import {erpResourceApi} from "../../../../../../services/erp-resource-api.service";
import {eventSchema, caseSchema, generalSchema} from "../create-tasks/schemas/create-activity-shema";

export const createTaskAction = (model: any, clearFormFields: any) => {
    return async (dispatch: any) => {
        dispatch(messageAction.show({title: 'Processing.....', type: MessageBarType.info}))
        let isValid = await formValidator(model.type, model);
        if (!isValid) {
            dispatch(messageAction.show({title: 'Invalid', type: MessageBarType.error}))

        } else {
            const {error, data} = await dispatch(erpResourceApi.endpoints.create.initiate({
                doc: 'DLAD Activity',
                payload: model
            }))

            if (error) {
                dispatch(messageAction.show({title: 'Error', type: MessageBarType.error}))
            }

            if (data) {
                dispatch(messageAction.show({title: 'Success [Created New Lead]', type: MessageBarType.success}))
                clearFormFields()
            }
        }
    }
}

const formValidator = (type: string, form: any) => {
    switch (type) {
        case 'Event' :
            return eventSchema.isValid(form)
            break;
        case 'Case':
            return caseSchema.isValid(form)
            break;
        default:
            return generalSchema.isValid(form)
            break;
    }
}