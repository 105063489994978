import {erpResourceApi} from "../../../../services/erp-resource-api.service";

export const updateEventAction = (model: any) => {
    return async (dispatch: any) => {
        const {error, data} = await dispatch(erpResourceApi.endpoints.update.initiate({
            doc: 'Event',
            name: model.name,
            payload: model
        }))
    }
}