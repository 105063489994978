import {erpResourceApi} from "../../../../../../services/erp-resource-api.service";
import * as messageAction from "../../../../../../store/reducers/general-ui/message.store";
import {MessageBarType} from "@fluentui/react";

export const createEventAction = (model: any, clearFormFields: any) => {
    return async (dispatch: any) => {
        dispatch(messageAction.show({title: 'Processing.....', type: MessageBarType.info}))
        // let isValid = await leadSchema.isValid(model);
        if (false) {
            dispatch(messageAction.show({title: 'Invalid', type: MessageBarType.error}))

        } else {
            const {error, data} = await dispatch(erpResourceApi.endpoints.create.initiate({
                doc: 'Event',
                payload: model
            }))

            if (error) {
                dispatch(messageAction.show({title: 'Error', type: MessageBarType.error}))
            }

            if (data) {
                dispatch(messageAction.show({title: 'Success [Created New Event]', type: MessageBarType.success}))
                clearFormFields()
            }
        }
    }
}