import useSafeQuery from "../../../../hooks/use-safe-query.hook";
import {useGetQuery} from "../../../../../services/erp-resource-api.service";
import {Text, Stack, CommandBar, ICommandBarItemProps} from "@fluentui/react";
import {save} from "../../actions/generic.actions";
import {setVisibility} from "../../../../../store/reducers/apps/admin/panel.store";
import {useDispatch} from "react-redux";

const NumberCardListConfig = (props: any) => {
    const dispatch = useDispatch()
    const {data} = useSafeQuery(useGetQuery, {doc: props.doctype, name: props.name})

    const listMarkup = data?.data.cards.map((line: any) => <Stack><Text>{line.name}</Text></Stack>) ?? []

    const _items: ICommandBarItemProps[] = [
        {
            key: 'add',
            text: 'Add',
            cacheKey: 'add',
            iconProps: {iconName: 'Add'},
            onClick: () => {
                dispatch(save('DLAD Query', data.data.name, data))
            }
        }
    ];

    return <div className={'container gx-0'}>
        <CommandBar
            items={_items}
            ariaLabel="Inbox actions"
            primaryGroupAriaLabel="Email actions"
            farItemsGroupAriaLabel="More actions"
        />
        {listMarkup}
    </div>
}

export default NumberCardListConfig