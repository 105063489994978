import {useGetQuery} from "../../../../services/erp-resource-api.service";
import {Text} from "@fluentui/react/lib/Text";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../store/store";

const DefaultTitleBar = ({type, id}: any) => {

    const {data} = useGetQuery({doc: type, name: id})

    // const titleMarkup = model.name && (<> - {model.name}</>)

    const markup = data ? (
        <div className={"py-1 pb-2 px-2 bg-light-gray"}>
            <Text variant={"xLarge"}>{data.data.title}</Text>
            <br/>
            <Text variant={"smallPlus"}>{data.data.sub_title} </Text>
        </div>
    ) : null

    return (markup)
}

export default DefaultTitleBar