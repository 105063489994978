import {erpMethodApi} from "../../../../../services/erp-method-api.service";
import {EXEC_QUERY_METHOD} from "../../../../../store/consts/erp-method.consts";

export const execAction = (name: any, params: any) => {
    return async (dispatch: any) => {
        return await
            dispatch(erpMethodApi.endpoints.execMethodApi.initiate(
                {method: EXEC_QUERY_METHOD, body: {name, ...params}},
                {subscribe: false, forceRefetch: true}
            ))
    }
}