import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardType,
    FontIcon,
    IconButton,
    IIconProps, mergeStyles,
    Text,
} from "@fluentui/react";
import {Link, useNavigate} from "react-router-dom";
import {useExecMethodApiQuery} from "../../../../../services/erp-method-api.service";
import useAllParams from "../../../../hooks/use-all-params.hook";
import useSafeQuery from "../../../../hooks/use-safe-query.hook";
import NumberCardTextShimmer from "../../shimmers/number-card-text-shimmer/number-card-text-shimmer.component";
import {useDispatch} from "react-redux";
import {setVisibility} from "../../../../../store/reducers/apps/admin/panel.store";
import EditButtonComponent from "../../../../common/edit-button/edit-button.component";

const QueryNumberCard = (props: any) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    let params = useAllParams();

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: "dlad_sap_connector.dlad_sap_connector.api.execute_query",
        body: {name: props.query, ...params},
    });

    const onSettingsClickHandler = () => {
        dispatch(setVisibility({visible: true, config: props}))
    }

    return (
        <DocumentCard
            className={"rounded-2"}
            type={DocumentCardType.compact}

        >
            <DocumentCardDetails>
                <div style={{backgroundColor: "#cccccc"}} className="d-flex justify-content-between align-items-center">
                    <Text
                        nowrap
                        className={"text-uppercase fw-bold p-2"}
                        variant={"smallPlus"}
                    >
                        {props.label}
                    </Text>
                    <EditButtonComponent config={props}/>
                </div>
                <div onClick={() => navigate("/app/page/" + props.page)}
                     style={{cursor: 'pointer'}}
                     className="d-flex justify-content-center align-items-center flex-fill p-2">
                    <Text variant="large" style={{color: `${props.color}`}}>
                        {data ? data.message.value : <NumberCardTextShimmer/>}
                    </Text>
                </div>
            </DocumentCardDetails>

        </DocumentCard>
    );
};

export default QueryNumberCard;
