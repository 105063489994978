import {Stack} from "@fluentui/react";

const MainUiLayout = ({children}: any) => {

    return (
        <>
            <Stack className='overflow-auto vh-100'>
                this is a stck lauypuit
                {children}
            </Stack>
        </>
    )
}

export default MainUiLayout