import {Link, MessageBar, MessageBarType} from "@fluentui/react";
import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/store";
import * as messageAction from "../../../store/reducers/general-ui/message.store";


const MessageBarComponent = () => {

    const dispatch = useDispatch()
    const {visible, model} = useSelector<RootStore, any>((state) => state.uiStore.message)
    const resetChoice = useCallback(() => dispatch(messageAction.hide()), []);

    const notificationMarkup = visible && <MessageBar
        messageBarType={model.type}
        isMultiline={false}
        onDismiss={resetChoice}
        dismissButtonAriaLabel="Close"
    >
        {model.title}
    </MessageBar>

    return <>
        {notificationMarkup}
    </>
}

export default MessageBarComponent