import {useDispatch} from "react-redux";
import useInput from "../../../../../hooks/use-input.hook";
import {CommandBar, DocumentCardDetails, Dropdown, Pivot, PivotItem, Stack, TextField} from "@fluentui/react";
import {createEventAction} from "../actions/events.actions";
import RelationalField from "../../../../../common/form-components/relational-fields/relational-field.component";
import {useSearchParams} from "react-router-dom";

const CreateEventsApp = () => {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [form, formHandler, formValueHandler, clearAll] = useInput()

    const dropDownHandler = (e: any, option: any, index: any) => {
        formValueHandler(e.target.id, option.text)
    }

    const relationalFieldHandler = ({key, text}: any) => {
        formValueHandler(key, text)
    }

    const save = async () => {
        let model = {
            ...form,
        }

        dispatch(createEventAction(model, clearAll))
    }

    const commandBarMarkup = (
        <div className={'mx-2 mb-2'}>
            <CommandBar
                items={[{
                    key: 'save',
                    text: 'Save',
                    iconProps: {iconName: 'Save'},
                    onClick: () => {
                        save()
                    },
                    ['data-automation-id']: 'newSaveButton', // optional
                }]}
                overflowButtonProps={{ariaLabel: "More commands"}}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
            />
        </div>
    )

    const basicInfoMarkup = (
        <Stack className="pb-1 px-2">
            <DocumentCardDetails className={'px-2 bg-white rounded-2'}>
                <div className={'row py-2'}>
                    <div className={'col-lg'}>
                        <TextField required value={form['subject']}
                                   onChange={formHandler} name={'subject'}
                                   label="Subject"/>
                    </div>
                    <div className={'col-lg-4'}>
                        <Dropdown
                            className={'me-2'}
                            placeholder="Event Category"
                            label="Event Category"
                            id={'event_type'}
                            onChange={dropDownHandler}
                            options={[
                                {key: 'Private', text: 'Private'},
                                {key: 'Public', text: 'Public'},
                                {key: 'Cancelled', text: 'Cancelled'},
                            ]}
                        />
                        <Dropdown
                            className={'me-2'}
                            placeholder="Status"
                            label="Status"
                            id={'status'}
                            onChange={dropDownHandler}
                            options={[
                                {key: 'open', text: 'Open'},
                                {key: 'closed', text: 'Closed'},
                            ]}
                        />
                    </div>
                    <div className={'col-lg-4'}>
                        <div className={'col-lg'}>
                            <TextField required type={'date'} value={form['starts_on']} onChange={formHandler}
                                       name={'starts_on'}
                                       label="Starts on"/>
                        </div>
                        <div className={'col-lg'}>
                            <TextField type={'date'} value={form['ends_on']} onChange={formHandler} name={'ends_on'}
                                       label="Ends on"/>
                        </div>
                    </div>

                </div>
            </DocumentCardDetails>
        </Stack>
    )

    const relatedDocumentMarkup = (<Stack className="pb-1 px-2">
            <DocumentCardDetails className={'px-2 bg-white rounded-2'}>
                <div className={'row py-2'}>
                    <div className={'col-lg-4'}>
                        {
                            searchParams.get('ref') && searchParams.get('refDoc')
                                ? <>
                                    <TextField value={searchParams.get('refDoc') ?? ''} onChange={formHandler}
                                               name={'related_document'}
                                               disabled={true}
                                               label="Related document type"/>
                                    <TextField value={searchParams.get('ref') ?? ''} onChange={formHandler}
                                               name={'related_document_id'}
                                               disabled={true}
                                               label="Related document id"/>
                                </>
                                : <RelationalField
                                    name={{
                                        main: {key: 'related_document', label: 'Related document type'},
                                        sub: {key: 'related_document_id', label: 'Related document id'}
                                    }}
                                    onChangeMain={relationalFieldHandler}
                                    onChangeSub={relationalFieldHandler}
                                    options={{
                                        main: {
                                            query: '3df621b418'
                                        }
                                    }}
                                    docConfig={[
                                        {
                                            label: "DLAD Site Visit",
                                            doc: "DLAD Site Visit",
                                            type: "Standard",
                                            query: 'DLAD Site Visit'
                                        }
                                    ]}
                                />
                        }
                    </div>
                </div>
            </DocumentCardDetails>
        </Stack>
    )


    const tabMarkup = (
        <Pivot overflowBehavior={'menu'}>
            <PivotItem
                headerText="Basic Information"
                headerButtonProps={{
                    'data-order': 1,
                }}
            >
                {basicInfoMarkup}
            </PivotItem>

            <PivotItem headerText="Related Document">
                {relatedDocumentMarkup}
            </PivotItem>
        </Pivot>
    )

    return <>
        {commandBarMarkup}
        {tabMarkup}
    </>
}

export default CreateEventsApp