import {ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType} from "@fluentui/react";
import {useBoolean} from '@fluentui/react-hooks';
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/store";
import {hide} from "../../../store/reducers/general-ui/alert.store";

const AlertDialog = () => {

    const dispatch = useDispatch()
    const {visible, model} = useSelector<RootStore, any>((state) => state.uiStore.alert)

    const dragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
    };
    const modalPropsStyles = {main: {maxWidth: 450}};
    const dialogContentProps = {
        type: DialogType.normal,
        title: model.title,
        subText: model.subtitle,
    };

    const [isDraggable, {toggle: toggleIsDraggable}] = useBoolean(false);
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: isDraggable ? dragOptions : undefined,
        }),
        [isDraggable],
    );

    return (
        <Dialog
            hidden={!visible}
            onDismiss={() => dispatch(hide())}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <DialogFooter>
                {/*<PrimaryButton onClick={() => dispatch(hide())} text="Send" />*/}
                <DefaultButton onClick={() => dispatch(hide())} text="Ok"/>
            </DialogFooter>
        </Dialog>
    )
}

export default AlertDialog