import {Text} from "@fluentui/react";

const UnauthorizedAlert = () => {

    return (
        <>
            <div className={'d-flex justify-content-center pt-5'}></div>
            <div className={'d-flex justify-content-center pt-5'}>
                <img height={'200'} src={'https://atlassian.design/static/LockClosed-3676d607e7ded3afddcd12fbf1bed02c.png'}/>
            </div>
            <div className={'d-flex justify-content-center pt-5'}>
                <Text variant={"xLarge"}>You don't have access to this page</Text>
            </div>
            <div className={'d-flex justify-content-center pt-1'}>
                <Text variant={"medium"}>Make sure you have the rights to access this page. If it does, ask IT manager for
                    permission to see the information</Text>
            </div>
        </>
    )
}

export default UnauthorizedAlert