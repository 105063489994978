import React, {useEffect, useState} from "react";
import {createCustomEqual} from "fast-equals";
import {isLatLngLiteral} from "@googlemaps/typescript-guards";
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import useAutoFit from "../../hooks/use-auto-fit.hook";
import Marker from "./components/map-marker.component";
import {GOOGLE_MAP_KEY} from "./consts/map.consts";
import {TextField} from "@fluentui/react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../store/store";

const render = (status: Status) => {
    return <h1>{status}</h1>;
};

const GoogleMap = ({geocodes}: any) => {

    const {locations} = useSelector<RootStore, any>((state) => state.apps.map)
    const [ref, setAutoFit] = useAutoFit()
    const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
    const [zoom, setZoom] = React.useState(7); // initial zoom
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
        lat: -37.73289395276815,
        lng: 144.8350208117412,
    });

    setAutoFit()

    const onClick = (e: google.maps.MapMouseEvent) => {
        // avoid directly mutating state
        setClicks([...clicks, e.latLng!]);
    };

    const onIdle = (m: google.maps.Map) => {
        console.log("onIdle");
        setZoom(m.getZoom()!);
        setCenter(m.getCenter()!.toJSON());
    };

    const form = (
        <div
            style={{
                padding: "1rem",
                flexBasis: "250px",
                height: "100%",
                overflow: "auto",
            }}
        >
            <TextField
                type="number"
                id="zoom"
                name="zoom"
                label={'Zoom'}
                value={zoom.toString()}
                // onChange={(event) => setZoom(Number(event))}
            />
            <TextField
                type="number"
                id="lat"
                name="lat"
                label={'Latitude'}
                value={center.lat.toString()}
            />
            <TextField
                type="number"
                id="lng"
                name="lng"
                label={'Longitude'}
                value={center.lng.toString()}
            />
            <br/>
            {/*<h6>{clicks.length === 0 ? "Click on map to add markers" : "Clicks"}</h6>*/}
            {/*{clicks.map((latLng, i) => (*/}
            {/*    <pre key={i}>{JSON.stringify(latLng.toJSON(), null, 2)}</pre>*/}
            {/*))}*/}
            {/*<PrimaryButton onClick={() => setClicks([])}>Clear</PrimaryButton>*/}
        </div>
    );

    const setMarkers = (status: any) => {
        if (status == 'SUCCESS') {
            let x = locations[0]
            // const location = new google.maps.LatLng('-37.73289395276815', '144.8350208117412', false)
            // setClicks([location])
        }
    }

    let markerMarkup = geocodes && geocodes.map(({location, bp}: any, i: number) => (
        <Marker label={bp} clickable={true} key={i} position={location}/>
    ))

    return (
        <div style={{display: "flex"}} ref={ref}>
            <Wrapper apiKey={GOOGLE_MAP_KEY} render={render} callback={(data) => setMarkers(data)}>
                <Map
                    center={center}
                    onClick={onClick}
                    onIdle={onIdle}
                    zoom={zoom}
                    style={{flexGrow: "1", height: "100%"}}
                >
                    {clicks.map((latLng, i) => (
                        <Marker key={i} position={latLng}/>
                    ))}
                    {markerMarkup}
                </Map>
            </Wrapper>
            {/* Basic form for controlling center and zoom of map. */}
            {/*{form}*/}
        </div>
    );
};

interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
}

const Map: React.FC<MapProps> = ({
                                     onClick,
                                     onIdle,
                                     children,
                                     style,
                                     ...options
                                 }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    React.useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    return (
        <>
            <div ref={ref} style={style}/>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, {map});
                }
            })}
        </>
    );
};

const deepCompareEqualsForMaps = createCustomEqual(
    (deepEqual) => (a: any, b: any) => {
        if (
            isLatLngLiteral(a) ||
            a instanceof google.maps.LatLng ||
            isLatLngLiteral(b) ||
            b instanceof google.maps.LatLng
        ) {
            return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
        }

        // TODO extend to other types

        // use fast-equals for other objects
        return deepEqual(a, b);
    }
);

function useDeepCompareMemoize(value: any) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

function useDeepCompareEffectForMaps(
    callback: React.EffectCallback,
    dependencies: any[]
) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export default GoogleMap