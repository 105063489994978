import {CommandBar, IButtonProps, ICommandBarStyles} from "@fluentui/react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/store";
import {deleteDoc, saveDoc, submitDoc, updateField} from "../../../../../../store/actions/general-ui/doc.actions";
import {SUBMIT_DOC} from "../../../../../../store/consts/erp-method.consts";

const overflowProps: IButtonProps = {ariaLabel: "More commands"};

const style: ICommandBarStyles = {
    root: {
        padding: 0,
    },
};

const BasicCommandBar = ({config}: any) => {

    const {model} = useSelector<RootStore, any>((state) => state.pagesStore.doc)
    const {page} = useSelector<RootStore, any>((state) => state.uiStore.ui)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const actionList = [
        {
            "command": "new",
            "action": () => {
                let actionCommand = config.data.actions.find((line: any) => line.command == 'new')
                navigate(`/app/page/${actionCommand.page}`)
            }
        },
        {
            "command": "new-with-ref",
            "action": () => {
                let actionCommand = config.data.actions.find((line: any) => line.command == 'new-with-ref')
                navigate(`/app/page/${actionCommand.page}?ref=${model.name}&refDoc=${page.view_doc_type}`)
            }
        },
        {
            "command": "save-doc",
            "action": () => {
                dispatch(saveDoc(page.view_doc_type, model.name, model))
            }
        },
        {
            "command": "submit-doc",
            "action": () => {
                let formData = new FormData()
                formData.append('doc', model)
                formData.append('action', "Submit")

                dispatch(submitDoc(SUBMIT_DOC, {doc: JSON.stringify(model), action: "Submit"}))
            }
        },
        {
            "command": "delete-doc",
            "action": () => {
                dispatch(deleteDoc(page.view_doc_type, model.name))
            }
        },
        {
            "command": "field-update",
            "action": function (config: any) {
                dispatch(saveDoc(page.view_doc_type, model.name, {...model, ...config}))
            }
        },
        {
            "command": "exportExcel",
            "action": () => {
                const items: any = {}
                const replacer = (key: any, value: any) => value === null ? '' : value // specify how you want to handle null values here
                const header = Object.keys(items[0])
                const csv = [
                    header.join(','), // header row first
                    ...items.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
                ].join('\r\n')


                const file = new Blob([csv], {type: 'csv'});
                const a = document.createElement("a"),
                    url = URL.createObjectURL(file);
                a.href = url;
                a.download = 'file.csv';
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 0);
            }
        },
    ]

    const items: any = config.data && config.data.actions.map((item: any, index: number) => {
        let parsedItem = JSON.parse(JSON.stringify(item))
        let filteredAction = actionList.find(action => action.command === parsedItem.command)
        let {action}: any = filteredAction ? filteredAction : {}
        let keyValue = parsedItem.config ? JSON.parse(parsedItem.config) : null

        delete parsedItem.type
        return {
            key: index,
            text: parsedItem.text,
            iconProps: {iconName: parsedItem.icon},
            onClick: keyValue ? action.bind(null, keyValue) : action
        }
    })


    return (
        <div className={'mx-2 mb-2'}>
            <CommandBar
                className={'bg-surface'}
                items={items}
                overflowButtonProps={overflowProps}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
            />
        </div>
    )
}

export default BasicCommandBar