import {VirtualizedComboBox} from "@fluentui/react";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getDocumentList, getStandardDocumentList} from "../actions/relational-field.actions";
import {array} from "yup";

interface IProp {
    name: {
        main: { key: string, label: string },
        sub: { key: string, label: string }
    },
    onChangeMain: any,
    onChangeSub: any,
    options: {
        main: { query: string }
    },
    docConfig: Array<{ label: string, doc: string, type: string, query: string }>
    required?: boolean
}

const DOCUMENT_CONFIG = [
    {
        doc: "DLAD Site Visit",
        type: "Standard",
        query: 'DLAD Site Visit'
    }
]

const RelationalField = ({name, onChangeMain, onChangeSub, options, docConfig, required = false}: IProp) => {
    const dispatch = useDispatch()

    const [relatedDocType, setRelatedDocType] = useState('')
    const [relatedDocList, setRelatedDocList] = useState([])

    useEffect(() => {
        let doc = getDocConfig(relatedDocType);
        let type = getSelectedType(doc)
        getRelatedDocumentList(type, doc?.query)
    }, [relatedDocType])

    const getDocConfig = (doc: String) => {
        return docConfig.find((line) => line.doc == doc)
    }

    const getSelectedType = (doc: any) => doc?.type ?? '';

    const onSelectType = (e: any, option: any) => {
        setRelatedDocType(option?.key ?? '')
        onChangeMain({key: name.main.key, text: option?.key ?? ''})
    }

    const onSelectId = (e: any, option: any) => {
        onChangeSub({key: name.sub.key, text: option?.key ?? '', keyValue: option})
    }

    const getRelatedDocumentList = async (type: String, query: any) => {
        switch (type) {
            case 'Standard':
                let standardLIst: any = await dispatch(getStandardDocumentList(query))
                setRelatedDocList(standardLIst)
                break;
            case 'erp':
                let sapList: any = await dispatch(getDocumentList({name: query}))
                setRelatedDocList(sapList)
                break;
        }
    }

    const relatedDocumentMarkup = <VirtualizedComboBox
        required={required}
        label={name.main.label.toString()}
        defaultSelectedKey="547"
        allowFreeform
        autoComplete="on"
        options={docConfig.map(line => ({key: line.doc, text: line.label}))}
        onChange={onSelectType}
        useComboBoxAsMenuWidth
    />

    const relatedDocumentIdMarkup = <VirtualizedComboBox
        required={required}
        label={name.sub.label.toString()}
        defaultSelectedKey="547"
        allowFreeform
        autoComplete="on"
        options={relatedDocList}
        disabled={!(relatedDocList.length > 0)}
        onChange={onSelectId}
        useComboBoxAsMenuWidth
    />

    return <>
        {relatedDocumentMarkup}
        {relatedDocumentIdMarkup}
    </>
}


export default RelationalField