import {useGetQuery} from "../../../../services/erp-resource-api.service";

const DefaultFormLayout = ({children, type, id}: any) => {

    const {data} = useGetQuery({method: `${type}/${id}`})
    let formName = 'form'

    if (data) {
        formName = data.data.form_name
    }
    const saveForm = () => {
        let model = sessionStorage.getItem(formName)
    }

    // const commandMarkup = <FormCommandBar formName={formName}/>
    //
    // const layoutMarkup = <Stack>
    //     {children}
    // </Stack>
    //
    // useEffect(() => {
    //     try {
    //         sessionStorage.setItem(formName, '{}')
    //     } catch {
    //         console.log('err')
    //     }
    // }, [data])

    return <>
        {/*{commandMarkup}*/}
        {/*{layoutMarkup}*/}
    </>
}

export default DefaultFormLayout