import ComponentFactory from "../lib-factory/library-component.factory";
import PageGenerator from "../../common/page-generator/page-generator.component";

const UiElementContainer = ({type, component_id, config_doc_type, config_ref_id, configs}: any) => {

    const page: any = ComponentFactory(type, component_id)

    const markup = page && (
        type === 'functional' ?
            <page.component type={config_doc_type} id={config_ref_id}/> :
            (
                <page.layout type={config_doc_type} id={config_ref_id}>
                    <PageGenerator configs={configs}/>
                </page.layout>
            )
    )

    return (
        <>
            {markup}
        </>
    )
}

export default UiElementContainer


// const LayoutWithSubComponent = ({type, component_id, config_doc_type, config_ref_id, configs}: any) => {
//     let page: any = ComponentFactory(type, component_id)
//     return <>
//         {type === "structural" ? <page.layout type={config_doc_type} id={config_ref_id}>
//             <PageGeneratorComponent configs={configs}/>
//         </page.layout> : <page.component type={config_doc_type} id={config_ref_id}/>}
//     </>
// }