import {useNavigate} from "react-router-dom";
import useSafeQuery from "../../../../hooks/use-safe-query.hook";
import {useListQuery} from "../../../../../services/erp-resource-api.service";
import {getFocusStyle, getTheme, ITheme, List, mergeStyleSets, Text} from "@fluentui/react";
import {useExecMethodApiQuery} from "../../../../../services/erp-method-api.service";
import {EXEC_QUERY_METHOD} from "../../../../../store/consts/erp-method.consts";

const theme: ITheme = getTheme();
const {palette, semanticColors, fonts} = theme;

const classNames = mergeStyleSets({
    container: {
        overflow: "auto",
        // maxHeight: 500,
    },
    itemCell: [
        getFocusStyle(theme, {inset: -1}),
        {
            minHeight: 54,
            padding: 10,
            boxSizing: "border-box",
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: "flex",
            selectors: {
                "&:hover": {background: palette.neutralLight},
            },
        },
    ],
    itemImage: {
        flexShrink: 0,
    },
    itemContent: {
        marginLeft: 10,
        overflow: "hidden",
        flexGrow: 1,
    },
    itemName: [
        fonts.medium,
        ,
        {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    ],
    itemIndex: {
        fontSize: fonts.small.fontSize,
        color: palette.neutralTertiary,
        marginBottom: 10,
    },
    chevron: {
        alignSelf: "center",
        marginLeft: 10,
        color: palette.neutralTertiary,
        fontSize: fonts.large.fontSize,
        flexShrink: 0,
    },
});

const BasicErpList = (props: any) => {

    const navigate = useNavigate()
    const {data, isSuccess} = useSafeQuery(useExecMethodApiQuery, {
        method: EXEC_QUERY_METHOD, body: {
            name: props.query,
        }
    })

    const onRenderCell: any = (
        item: any,
        index: number,
        isScrolling: boolean
    ): JSX.Element => {
        return (
            <div className={classNames.itemCell} data-is-focusable={true} onClick={() => {
                navigate(`/app/page/${props.page}?id=${item.id}`)
            }}>
                <div className={classNames.itemContent}>
                    <Text variant={'medium'}>{item.title}</Text>
                    <br/>
                    <Text variant={'xSmall'}>{item.subTitle}</Text>
                </div>
            </div>
        );
    };

    const listMarkup = data && <List items={data.message} onRenderCell={onRenderCell}/>

    return (
        <>
            {listMarkup}
            {/*{JSON.stringify(data)}*/}
        </>
    )
}

export default BasicErpList