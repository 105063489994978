import useUiConfigs from "../../../hooks/use-ui-config.hook";
import QueryNumberCard from "./query-number-card/query-number-card.component";
import NumberCardShimmer from "../shimmers/number-card-shimmer/number-card-shimmer.component";

const NumberCard = ({card}: any) => {

    const {data} = useUiConfigs('DLAD C Number Card', card)
    let markup = (<NumberCardShimmer/>)

    if (data) {
        if (data.type === "erp") markup =
            (<QueryNumberCard {...data}/>)
    }


    return (markup)
}

export default NumberCard