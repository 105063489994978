import useSafeQuery from "../../../../../hooks/use-safe-query.hook";
import {useExecMethodApiQuery} from "../../../../../../services/erp-method-api.service";
import _ from "lodash";
import {FontIcon, Shimmer, Text} from "@fluentui/react";

const ResolvableTupleCard = (props: any) => {

    const {data, error} = useSafeQuery(useExecMethodApiQuery, {
        method: "dlad_sap_connector.dlad_sap_connector.api.execute_query",
        body: {name: props.resolvable_query, id: props.value},
    });

    const markup = data ?
        (
            <span><Text variant={'small'}>{data.message.value}</Text></span>
        ) : <Shimmer className={'mt-1'} width="90%"/>

    return markup
}

export default ResolvableTupleCard