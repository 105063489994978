import {useGetQuery} from "../../../../services/erp-resource-api.service";
import ConfigurableColLayout from "./components/configurable-col.layout";

const DefaultColLayout = ({children, type, id}: any) => {

    const {data} = useGetQuery({
        doc: type,
        name: id
    })

    let markup = (
        <div className={'col-lg'}>{children}</div>
    )

    if (data) {
        markup = <ConfigurableColLayout data={data}>{children}</ConfigurableColLayout>
    }

    return markup

}

export default DefaultColLayout