import {Pivot, PivotItem} from "@fluentui/react";
import _ from 'lodash'
import {useBoolean} from "@fluentui/react-hooks";
import useSafeQuery from "../../../hooks/use-safe-query.hook";
import {useGetQuery} from "../../../../services/erp-resource-api.service";
import PageGeneratorComponent from "../../../common/page-generator/page-generator.component";

const PivotComponent = ({type, id}: any) => {

    const [overflow] = useBoolean(true);
    const [tabs] = useBoolean(false);
    const {data} = useSafeQuery(useGetQuery, {doc: type, name: id})

    const model = data && _.cloneDeep(data.data.components)
    const list = model && getNestedChildren(model, 0)

    const pivotItemsMarkup = list && list.map((item: any, key: number) => {
        return (
            <PivotItem headerText={item.attr} key={key}>
                <PageGeneratorComponent configs={item.configs}/>
            </PivotItem>
        )
    })

    return (
        <Pivot
            onLinkClick={(s, d) => {
            }}
            linkFormat={tabs ? "tabs" : "links"}
            overflowBehavior={overflow ? "menu" : "none"}
        >
            {pivotItemsMarkup}
        </Pivot>
    );
}

const getNestedChildren = (models: any, parentId: any) => {
    let nestedTreeStructure = [];
    let length = models.length;

    for (let i = 0; i < length; i++) {
        let model = models[i];

        if (model.tree_position == parentId) {
            let children = getNestedChildren(models, model.idx);

            if (children.length > 0) {
                model.configs = children;
            }

            nestedTreeStructure.push(model);
        }
    }

    return nestedTreeStructure;
}

export default PivotComponent