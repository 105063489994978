import {useRef} from "react";
import {PrimaryButton} from "@fluentui/react";
import {Loader} from "@googlemaps/js-api-loader"
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import GoogleMap from "../google-maps/google-maps.app";

const FileUploader = () => {

    const inputFile: any = useRef('')

    const onButtonClick = () => {
        // @ts-ignore
        inputFile.current.click();
    };

    const handleValueChange = (e: any) => {
        const file = inputFile.current.files[0]
        console.log(file)

        let data = new FormData();
        data.append("docname", "5a91affdda");
        data.append("doctype", "DLAD Page");
        data.append("folder", "Home/Attachments");
        data.append("file", file, file.name);

        let xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                console.log(this.responseText);
            }
        });

        xhr.open("POST", "https://core.teadrop.us/api/method/upload_file", true);
        xhr.setRequestHeader("Accept", "application/json");

        xhr.send(data);
        console.log(file)
    }

    return (
        <>
            {/*<input id='file' ref={inputFile} style={{display: 'none'}} type={'file'} onChange={handleValueChange}/>*/}
            {/*<PrimaryButton onClick={onButtonClick}>Open file upload window</PrimaryButton>*/}
            <GoogleMap/>
        </>
    )
}

export default FileUploader