import {Stack} from "@fluentui/react";

const DefaultHorizontalStackLayout = ({children}: any) => {

    return (
        <>
            <Stack horizontal>
                {children}
            </Stack>
        </>
    )
}

export default DefaultHorizontalStackLayout