import React from 'react'
import FullCalendar, {formatDate} from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, {Draggable} from "@fullcalendar/interaction"
import useSafeQuery from "../../hooks/use-safe-query.hook";
import {useListQuery} from "../../../services/erp-resource-api.service";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import moment from 'moment'
import {updateEventAction} from "./actions/calendar.actions";
import {useDispatch} from "react-redux";

const DATE_FORMAT = 'YYYY-MM-DD h:mm:ss';

const CalenderApp = () => {

    const dispatch = useDispatch()

    const {data} = useSafeQuery(useListQuery, {
        method: 'Event',
        fields: ['name', 'subject', 'event_category', 'event_type', 'starts_on', 'ends_on', 'status', 'related_document', 'related_document_id'],
        fetchCount: 1000
    })

    const handleDateClick = (arg: any) => {

    }

    const renderEventContent = (eventInfo: any) => (
        <>
            <b>{eventInfo.timeText}</b>
            <i>{eventInfo.event.title}</i>
        </>
    )

    const eventUpdateHandler = (args: any) => {
        const model = {
            name: args.event._def.extendedProps.name,
            starts_on: moment(args.event.start).format(DATE_FORMAT),
            ends_on: moment(args.event.end ? args.event.end : args.event.start).format(DATE_FORMAT),
        }

        dispatch(updateEventAction(model))
    }

    const model = (data && data.data) ? data.data.map((line: any) => ({
        title: `${line.name} - ${line.subject}`,
        start: line.starts_on,
        end: line.ends_on,
        status: line.status,
        name: line.name
    })) : []

    return (
        <div className={'p-2'}>
            <FullCalendar
                headerToolbar={{
                    start: 'title',
                    center: 'dayGridMonth,timeGridWeek,listWeek,dayGridWeek',
                    end: 'today prev,next'
                }}
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                eventContent={renderEventContent}
                initialView="dayGridMonth"
                events={model}
                dateClick={handleDateClick}
                editable={true}
                droppable={true}
                eventDurationEditable={true}
                snapDuration={'00:15:00'}
                // eventDragStop={}
                eventChange={eventUpdateHandler}
            />
        </div>
    )
}

export default CalenderApp