import {combineReducers} from "redux";

import _uiStateStore from './general-ui';
import _pagesStore from './page';
import _erpStore from './lib/erp-data.store';
import {erpResourceApi} from "../../services/erp-resource-api.service";
import {erpMethodApi} from "../../services/erp-method-api.service";
import _adminPrivilegesStore from "./admin-privileges/admin-privileges.store";
import _appStore from './apps'
import googleMapApi from "../../services/google-map.service";

const rootReducer = combineReducers({
    uiStore: _uiStateStore,
    pagesStore: _pagesStore,
    erp: _erpStore,
    apps: _appStore,
    privileges: _adminPrivilegesStore,
    [erpResourceApi.reducerPath]: erpResourceApi.reducer,
    [erpMethodApi.reducerPath]: erpMethodApi.reducer,
    [googleMapApi.reducerPath]: googleMapApi.reducer
});

export default rootReducer;
