import useUiConfigs from "../../../hooks/use-ui-config.hook";
import {FontIcon, Stack, Text} from "@fluentui/react";
import TupleCard from "../tuple-card/tuple-card.component";
import {setVisibility} from "../../../../store/reducers/apps/admin/panel.store";
import {useDispatch} from "react-redux";

const TupleCardContainer = ({type, id}: any) => {

    const dispatch = useDispatch()
    const {data} = useUiConfigs(type, id)

    const onSettingsClickHandler = () => {
        dispatch(setVisibility({visible: true, config: {doctype: type, name: id}}))
    }

    const titleMarkUp = data.title && (
        <div className="d-flex justify-content-between align-items-center">
            <Text className={'text-uppercase fw-bold'}
                  variant={'smallPlus'}>{data.title}
            </Text>
            <FontIcon aria-label="Settings" iconName="Settings"
                      style={{fontSize: 12, cursor: 'pointer'}}
                      onClick={onSettingsClickHandler}
                      className={'pe-2'}/>
        </div>
    )

    const linesMarkup = data.tuples && data.tuples.map((line: any) => (
        <TupleCard {...line} key={line.name}/>))

    return <>
        <Stack className={'p-2 m-2 bg-white rounded-2'}>
            {titleMarkUp}
            {linesMarkup}
        </Stack>
    </>
}

export default TupleCardContainer