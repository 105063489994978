import {Shimmer, ShimmerElementsGroup, ShimmerElementType} from "@fluentui/react";

const wrapperStyle = {display: 'flex'};

const NumberCardShimmer = () => {
    const getCustomElementsExampleThree = (): JSX.Element => {
        return (
            <div style={wrapperStyle}>
                <ShimmerElementsGroup
                    width={'300px'}
                    shimmerElements={[
                        {type: ShimmerElementType.line, height: 106, width: 300},
                        {type: ShimmerElementType.line, height: 106, width: 300},
                    ]}
                />
            </div>
        );
    };

    return <Shimmer className={"m-2"} customElementsGroup={getCustomElementsExampleThree()}/>
}

export default NumberCardShimmer