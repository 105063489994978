import {
    CommandBar,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    ICommandBarItemProps,
    IDragDropContext,
    ISelection,
    mergeStyles,
    Selection,
    Text
} from "@fluentui/react";
import {save} from "../../actions/generic.actions";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import useSafeQuery from "../../../../hooks/use-safe-query.hook";
import {useGetQuery} from "../../../../../services/erp-resource-api.service";
import TableHeaderEditor from "./table-header-editor/table-header-editor.component";

const TableConfig = (props: any) => {

    let selection: ISelection = new Selection()
    let _draggedItem: any
    let _draggedIndex: any

    const dispatch = useDispatch()
    const [lines, setLines] = useState([])

    const {isSuccess, data} = useSafeQuery(useGetQuery, {doc: props.doctype, name: props.name})

    useEffect(() => {
        if (data && data.data) setLines(data.data.header_fields.map((line: any) => ({...line, id: line.idx})))
    }, [data])

    const _items: ICommandBarItemProps[] = [
        {
            key: 'save',
            text: 'Save',
            cacheKey: 'save',
            iconProps: {iconName: 'Save'},
            onClick: () => {
                let model = _.cloneDeep(lines);

                model = model.map((line: any, index: number) => {
                    line.idx = index + 1;
                    return line
                }) as never
                dispatch(save(props.doctype, props.name, {header_fields: model}))
            }
        }
    ];

    const headerFields: any = [
        {name: 'idx', label: 'Sequence'},
        {name: 'key', label: 'Path'},
        {name: 'label', label: 'Title'},
        {name: 'data_type', label: 'Type'},
        {name: 'min_width', label: 'Min Width'},
        {name: 'max_width', label: 'Max Width'}
    ]

    const columns: IColumn[] = headerFields.map((value: any) => ({
            key: value.name,
            name: value.label,
            fieldName: value.name,
            isResizable: true,
            editable: value.editable,
        })
    )

    const dragEnterClass = mergeStyles({
        backgroundColor: 'red',
    });

    const _insertBeforeItem =
        (item: any) => {
            const draggedItems = selection.isIndexSelected(_draggedIndex)
                ? (selection.getSelection())
                : [_draggedItem!];

            const insertIndex = lines.indexOf(item as never)
            let items: any = lines.filter(itm => draggedItems.indexOf(itm) === -1);

            // @ts-ignore
            items.splice(insertIndex, 0, ...draggedItems);

            setLines(items)
        }

    const _getDragDropEvents = () => {
        return {
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDragEnter: (item?: any, event?: DragEvent) => {
                // return string is the css classes that will be added to the entering element.
                return dragEnterClass;
            },
            onDragLeave: (item?: any, event?: DragEvent) => {
                return;
            },
            onDrop: (item?: any, event?: DragEvent) => {
                if (_draggedItem) {
                    _insertBeforeItem(item);
                }
            },
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                _draggedItem = item;
                _draggedIndex = itemIndex!;
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                _draggedItem = undefined;
                _draggedIndex = -1;
            },
        };
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        return _.get(model, column.fieldName);
    }

    return (
        <div className={'gx-0'}>
            <CommandBar
                items={_items}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
            />

            <Text className="ms-2 mt-1" variant={'smallPlus'}></Text>
            <div>
                <div className={'row'}>
                    <div className={'col-6'}>
                        <DetailsList
                            items={lines}
                            columns={columns}
                            selection={selection}
                            compact={true}
                            setKey="none"
                            isHeaderVisible={true}
                            layoutMode={DetailsListLayoutMode.justified}
                            selectionMode={0}
                            onRenderItemColumn={onRenderItemColumn}
                            dragDropEvents={_getDragDropEvents()}
                        />
                    </div>
                </div>
                <br/>
                <br/>
                <TableHeaderEditor model={lines}/>
            </div>

        </div>
    )
}

export default TableConfig