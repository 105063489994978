import {VirtualizedComboBox} from "@fluentui/react";
import React, {useEffect, useState} from "react";
import {
    getDocumentList,
    getStandardDocumentList,
} from "../actions/auto-compleate-text-field.actions";
import {useDispatch} from "react-redux";

interface IProps {
    label?: string
    id: string
    onChange: any
    options: IOptions
    required?: boolean
    keyValuePair?: { key: string, text: string }
}

interface IOptions {
    type: string
    query: string | { name: string, id?: string }
    filters?: any
    keypair?: boolean
}

const AutoCompleteTextField =
    ({
         label,
         id,
         options,
         required = false,
         onChange,
         keyValuePair = {key: 'key', text: 'text'}
     }: IProps) => {

        const dispatch = useDispatch()
        const [list, setList] = useState([])

        useEffect(() => {
            getRelatedDocumentList(options)
        }, [options])

        const onSelect = (e: any, option: any) => {
            onChange(id, option?.key ?? '')
        }

        const getRelatedDocumentList = async ({type, query, filters = []}: IOptions) => {
            switch (type) {
                case 'Standard':
                    let standardLIst: any = await dispatch(getStandardDocumentList(query, filters, keyValuePair))
                    setList(standardLIst)
                    break;
                case 'erp':
                    let sapList: any = await dispatch(getDocumentList(query, keyValuePair))
                    setList(sapList)
                    break;
            }
        }

        const textFieldMarkup = <VirtualizedComboBox
            required={required}
            label={label}
            defaultSelectedKey="547"
            allowFreeform
            autoComplete="on"
            options={list}
            onChange={onSelect}
            useComboBoxAsMenuWidth
        />

        return (textFieldMarkup)
    }

export default React.memo(AutoCompleteTextField)