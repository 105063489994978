import DefaultDataTable from "../ui/default-data-table/default-data-table.component";
import DefaultTitleBar from "../ui/default-title-bar/default-title-bar.component";
import DashboardFilters from "../ui/dashboard-filter/dashboard-filters.component";
import NumberCardList from "../ui/number-card-list/number-card-list.component";
import CalenderApp from "../../apps/calender/calender.app";
import CreateLeadsPage from "../../apps/crm/pages/leads/create-leads/create-leads.page";
import FileUploader from "../../apps/file-uploader/file-uploader.component";
import DefaultCommandBar from "../ui/defualt-command-bar/default-command-bar.component";
import TupleCardContainer from "../ui/tuple-card-container/tuple-card-container.component";
import Pivot from "../ui/pivot/pivot.componet";
import DefaultList from "../ui/default-list/default-list.component";
import CreateSiteVisitsPage from "../../apps/crm/pages/site-visit/create-site-visits.page";
import CreateEventsApp from "../../apps/crm/pages/events/create-events/create-events.app";
import SiteVisitMap from "../../apps/crm/pages/site-visit-map/site-visit-map";
import CreateTasksPage from "../../apps/crm/pages/tasks/create-tasks/create-tasks.page";
import SiteVisitSuggestions from "../../apps/crm/pages/site-visit-suggestions/site-visit-suggestions.page";

const COMPONENT_CONFIG: any = [
    {
        componentId: "app-calendar",
        component: CalenderApp
    },
    {
        componentId: "app-leads-create",
        component: CreateLeadsPage
    },
    {
        componentId: "app-tasks-create",
        component: CreateTasksPage
    },
    {
        componentId: "app-site-visit-create",
        component: CreateSiteVisitsPage
    },
    {
        componentId: "app-site-visit-suggestions",
        component: SiteVisitSuggestions
    },
    {
        componentId: "app-event-create",
        component: CreateEventsApp
    },
    {
        componentId: "app-site-visit-map",
        component: SiteVisitMap
    },
    {
        componentId: "app-file-uploader",
        component: FileUploader
    },
    {
        componentId: "main table",
        component: DefaultDataTable //quickAction={false} method={"DLAD Sales Order"} fields={["name", "status"]} filters={[["DLAD Sales Order", "status", "=", "Open"]]}
    },
    // {
    //     componentId: "dock table",
    //     component: DockTableWrapper
    // },
    {
        componentId: "number card list",
        component: NumberCardList
    },
    {
        componentId: "basic command bar",
        component: DefaultCommandBar
    },
    {
        componentId: "basic title bar",
        component: DefaultTitleBar
    },
    {
        componentId: "basic list",
        component: DefaultList
    },
    {
        componentId: 'tuple card container',
        component: TupleCardContainer
    },
    {
        componentId: "pivot",
        component: Pivot
    },
    // {
    //     componentId: 'sub dashboard',
    //     component: SubDashboard
    // },
    // {
    //     componentId: 'chart card',
    //     component: ChartCardComponent
    // },
    // {
    //     componentId: 'horizontal divider',
    //     component: HorizontalDividerComponent
    // },
    {
        componentId: 'dashboard filter',
        component: DashboardFilters
    },
    // {
    //     componentId: 'form component',
    //     component: FormComponent
    // }
]
export default COMPONENT_CONFIG