import useAutoFit from "../../../hooks/use-auto-fit.hook";
import {useEffect} from "react";

const DefaultScrollLayout = ({children}: any) => {

    const [ref, setAutoFit] = useAutoFit()

    useEffect(() => {
        setAutoFit()
    })

    return (
        <>
            <div ref={ref} className="overflow-auto">
                {children}
            </div>
        </>
    )
}

export default DefaultScrollLayout