import * as loginPageActions from './../../reducers/page/login/login-page.store'
import * as alertActions from './../../reducers/general-ui/alert.store'
import usersApi from "../../../services/erp-users.service";

const loginAction = (form: any, navigate: any) => {
    return async (dispatch: any) => {
        dispatch(loginPageActions.showLoading())
        const {error, data} = await dispatch(usersApi.endpoints.login.initiate(form))

        dispatch(loginPageActions.hideLoading())

        if (!error) navigate('/app')
        else dispatch(alertActions.show({title: 'invalid', subtitle: ""}))
    }
}

export {
    loginAction
}