import {erpResourceApi} from "../../../services/erp-resource-api.service";
import * as messageAction from "../../reducers/general-ui/message.store";
import {MessageBarType} from "@fluentui/react";
import {erpMethodApi} from "../../../services/erp-method-api.service";

export const saveDoc = (doc: any, name: any, payload: any) => {
    return async (dispatch: any) => {
        dispatch(messageAction.show({title: 'Saving', type: MessageBarType.info}))
        const {error, data} = await dispatch(erpResourceApi.endpoints.update.initiate({doc, name, payload}))

        if (!error) {
            dispatch(messageAction.show({title: 'Successful [Updated Document]', type: MessageBarType.success}))
            window.location.reload()
        } else dispatch(messageAction.show({title: `Failed ${error.data.exc_type}`, type: MessageBarType.error}))
    }
}

export const deleteDoc = (doc: any, name: any) => {
    return async (dispatch: any) => {
        let confirmation = window.confirm("Are you sure ?")
        if (confirmation) {
            dispatch(messageAction.show({title: 'Deleting', type: MessageBarType.info}))
            const {error, data} = await dispatch(erpResourceApi.endpoints.delete.initiate({doc, name}))

            if (!error) {
                dispatch(messageAction.show({title: 'Successful [Updated Document]', type: MessageBarType.success}))
                window.location.reload()
            } else dispatch(messageAction.show({title: `Failed ${error.data.exc_type}`, type: MessageBarType.error}))

        }
    }
}

export const updateField = (doc: any, name: any, payload: any, updates: any) => {
    return async (dispatch: any) => {
        dispatch(messageAction.show({title: 'Saving', type: MessageBarType.info}))
        const {error, data} = await dispatch(erpResourceApi.endpoints.update.initiate({doc, name, payload}))

        if (!error) {
            dispatch(messageAction.show({title: 'Successful [Updated Document]', type: MessageBarType.success}))
            window.location.reload()
        } else dispatch(messageAction.show({title: `Failed ${error.data.exc_type}`, type: MessageBarType.error}))
    }
}

export const submitDoc = (method: any, body: any) => {
    return async (dispatch: any) => {
        dispatch(messageAction.show({title: 'Processing...', type: MessageBarType.info}))
        const {error, data} = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({method, body}))

        if (!error) {
            dispatch(messageAction.show({title: 'Successful [Updated Document]', type: MessageBarType.success}))
        } else dispatch(messageAction.show({title: `Failed ${error.data.exc_type}`, type: MessageBarType.error}))
    }
}