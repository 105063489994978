const useSearchParamModel = () => {

    const getSearchParams = (searchParams: any) => {
        let currentParams = {}
        for (let key of searchParams.keys()) currentParams = {...currentParams, [key]: searchParams.get(key)}
        return currentParams
    }

    return getSearchParams
}

export default useSearchParamModel