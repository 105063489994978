import {useSearchParams} from "react-router-dom";
import useSearchParamModel from "../../../../../hooks/use-search-param.hook";
import {DatePicker, defaultDatePickerStrings, IDatePickerStyles} from "@fluentui/react";
import moment from "moment";

const styles: Partial<IDatePickerStyles> = {textField: {width: 300}};

const FilterDatePicker = (props: any) => {
    const [searchParam, setSearchParam] = useSearchParams()
    const gerSearchParamModel = useSearchParamModel()

    const _onSelectDate = (value: any) => {
        setSearchParam({...gerSearchParamModel(searchParam), [props.qp_id]: moment(value).format('MM-DD-YYYY')})
    }

    const _formatDate = (date: Date | undefined) => {
        return !date ? '' : moment(date).format('MM-DD-YYYY')
    }

    return (
        <DatePicker
            label={props.label}
            className={'me-2'}
            placeholder="Select a date..."
            ariaLabel="Select a date"
            strings={defaultDatePickerStrings}
            // formatDate={_formatDate}
            onSelectDate={_onSelectDate}
            styles={styles}
        />
    )
}

export default FilterDatePicker