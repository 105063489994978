import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/store";
import {FontIcon, Stack, Text} from "@fluentui/react";
import PageGenerator from "../../../common/page-generator/page-generator.component";
import UnauthorizedAlert from "../../../common/unauthorized-alert/unauthorized-alert.component";
import PageLoadingAlert from "../../../common/page-loading-alert/page-loading-alert.component";
import DocPage from "../doc-page/doc.page";
import {setVisibility} from "../../../../store/reducers/apps/admin/panel.store";
import _ from 'lodash'

const FactoryPage = () => {

    const dispatch = useDispatch()
    const {page} = useSelector<RootStore, any>((state) => state.uiStore.ui)

    if (page == 'unauthorized') {
        console.log(page)
    }

    let markup

    if (page && page != 'unauthorized') {
        switch (page.type) {
            case 'dashboard':
                markup = (
                    <Stack>
                        <PageGenerator configs={page.layout.configs}/>
                    </Stack>
                )
                break
            case 'doc':
                markup = (<DocPage {...page}/>)
        }
    } else if (page && page == 'unauthorized') {
        markup = (<UnauthorizedAlert/>)
    } else {
        markup = (<PageLoadingAlert/>)
    }

    const onSettingsClickHandler = () => {
        let model: any = _.cloneDeep(page);
        model.doctype = 'DLAD Page'

        dispatch(setVisibility({visible: true, config: model}))
    }

    return (
        <>
            <div style={{backgroundColor: "#cccccc"}} className="d-flex justify-content-between align-items-center">
                <Text
                    nowrap
                    className={"text-uppercase fw-bold p-2"}
                    variant={"smallPlus"}
                >
                    Page Administration
                </Text>
                <FontIcon aria-label="Settings" iconName="Settings"
                          style={{fontSize: 12, cursor: 'pointer'}}
                          onClick={onSettingsClickHandler}
                          className={'pe-2'}/>
            </div>
            {markup}

        </>
    )
}

export default FactoryPage