import {DocumentCardDetails, Stack, Text} from "@fluentui/react";
import useUiConfigs from "../../../hooks/use-ui-config.hook";
import NumberCard from "../number-card/number-card.component";
import EditButtonComponent from "../../../common/edit-button/edit-button.component";

const NumberCardList = ({type, id}: any) => {

    const {data} = useUiConfigs(type, id)

    const titleMarkup = data.title && (
        <Text className={'text-uppercase fw-bold pt-1'}
              variant={'smallPlus'}>{data.title}
        </Text>
    )

    const numberCardsMarkup = data.cards && data.cards.map((line: any, index: any) => (
        <div className={'col p-2'} key={index}>
            <NumberCard {...line} />
        </div>
    ))

    return (
        <Stack className="pb-1 px-2">

            <DocumentCardDetails className={'px-2 bg-white rounded-2'}>
                <div className={'d-flex justify-content-between align-items-center'}>
                    {titleMarkup}
                    <EditButtonComponent config={{doctype: type, name: id}}/>
                </div>
                <div className={'row row-cols-1 row-cols-md-5 px-1'}>
                    {numberCardsMarkup}
                </div>
            </DocumentCardDetails>
        </Stack>
    )
}

export default NumberCardList