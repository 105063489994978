import React from "react";
import {CommandBar, Dropdown} from "@fluentui/react";
import useInput from "../../../../hooks/use-input.hook";

const SiteVisitSuggestions = () => {

    const [form, formHandler, formValueHandler, clearAll] = useInput()

    const commandBarMarkup =
        (
            <div className={'mx-2 mb-2'}>
                <CommandBar
                    items={[{
                        key: 'save',
                        text: 'Save',
                        iconProps: {iconName: 'Save'},
                        onClick: () => {
                        },
                        ['data-automation-id']: 'newSaveButton', // optional
                    }]}
                    overflowButtonProps={{ariaLabel: "More commands"}}
                    ariaLabel="Inbox actions"
                    primaryGroupAriaLabel="Email actions"
                    farItemsGroupAriaLabel="More actions"
                />
            </div>
        )

    const ownerMarkup =
        (<div className={"py-1 pb-2 px-2"}>
                <div className={'d-lg-flex'}>
                    <Dropdown
                        className={'me-2'}
                        placeholder="Select Owner"
                        label="Owner"
                        id={'owner'}
                        onChange={() => {
                        }}
                        options={[]}
                        styles={{dropdown: {width: 300}}}
                    />
                </div>
                <br/>
            </div>
        )

    return (<>
        <h1>Test Page</h1>
    </>)
}

export default SiteVisitSuggestions