import {Dropdown, IDropdownOption, IDropdownStyles} from "@fluentui/react";
import useSafeQuery from "../../../../../hooks/use-safe-query.hook";
import {useExecMethodApiQuery} from "../../../../../../services/erp-method-api.service";
import {useNavigate, useSearchParams} from "react-router-dom";
import React from "react";
import useSearchParamModel from "../../../../../hooks/use-search-param.hook";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/store";
import {set} from "../../../../../../store/reducers/lib/erp-data.store";


const dropdownStyles: Partial<IDropdownStyles> = {dropdown: {width: 300}};

const FilterDropdown = (props: any) => {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [searchParam, setSearchParam] = useSearchParams()
    const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();
    const [selectedItemList, setSelectedItemList] = React.useState([]);
    const gerSearchParamModel = useSearchParamModel()

    // const {drop} = useSelector<RootStore, any>((state) => state.erp)
    // dispatch(set({_key: props.qp_id, value: 'DINESH LIYTANAGE'}))

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: 'dlad_sap_connector.dlad_sap_connector.api.execute_query',
        body: {name: props.query}
    })

    const onChangeHandler = (event: any, item: any) => {
        switch (props.mode) {
            case 'single':
                setSearchParam({...gerSearchParamModel(searchParam), [props.qp_id]: item.key})
                break;
            case 'multi':
                let list: any = item.selected ? [...selectedItemList, item] : selectedItemList.filter((line: any) => line.key != item.key)
                setSelectedItemList(list)
                setSearchParam({
                    ...gerSearchParamModel(searchParam),
                    [props.qp_id]: [list.map((line: any) => line.key.toString())]
                })
                break;
            default :
                break;
        }

        setSelectedItem(item);
    }

    const markup = data ? (
        <Dropdown
            className={'me-2'}
            label={props.label}
            multiSelect={props.mode == 'multi' ? true : false}
            onChange={onChangeHandler}
            placeholder="Select an option"
            options={data.message}
            styles={dropdownStyles}
        />
    ) : null

    return (markup)
}

export default FilterDropdown