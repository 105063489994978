import {Spinner, SpinnerSize, Stack, Text, TextField} from "@fluentui/react";
import React from "react";
import {PrimaryButton} from "@fluentui/react/lib-amd";
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {loginAction} from "../../../store/actions/user/users.actions";
import {RootStore} from "../../../store/store";
import useInput from "../../hooks/use-input.hook";
import {getAppConfigAction} from "../../../store/actions/general-ui/general-ui.actions";


const LoginPage = () => {

    const dispatch = useDispatch();
    const {isLoading} = useSelector<RootStore, any>((state) => state.pagesStore.loginPage)
    const {appConfig} = useSelector<RootStore, any>((state) => state.uiStore.ui)

    if (!appConfig) {
        dispatch(getAppConfigAction())
    }

    const navigate = useNavigate()

    const formGroup = {
        usr: {key: 'usr', label: 'Username', type: 'text'},
        pwd: {key: 'pwd', label: 'Password', type: 'password'}
    }

    const [form, setForm] = useInput()

    const loginHandler = async () => {
        try {
            dispatch(loginAction(form, navigate))
        } catch {
            alert("login fail")
        }
    }

    const keyUpHandler = (e: any) => {
        if (e.key == 'Enter') {

        }
    }

    const primaryActionMarkup = isLoading ?
        (<Spinner size={SpinnerSize.medium}/>) :
        (<PrimaryButton onClick={loginHandler} text="Log in"
                        allowDisabledFocus/>)

    return (
        <Stack onKeyPress={keyUpHandler}>
            <Stack className="row vh-100 gx-0">
                <Stack className={`col-md-6 rounded-corner-none h-100`}>
                    <Stack horizontalAlign={'center'} horizontal className="h-100 bg-primary">
                        <Stack verticalAlign={'center'}>
                            <Text className="display-3 text-white">{appConfig?.login_screen_title}</Text>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack className="col-md-6 rounded-corner-none h-100">
                    <Stack horizontalAlign={'center'} horizontal className="h-100">
                        <Stack.Item align={'center'}>
                            <Stack
                                className={'border-secondary card border-1'} style={{width: '23.5rem'}}>
                                <Stack className="text-start mt-3 ms-3">
                                    <Text variant={"xxLarge"}>Log in</Text>
                                    <Text variant={"medium"}>Continue to {appConfig?.login_screen_title}</Text>
                                </Stack>
                                <Stack className="card-body rounded-corner-none">
                                    <Stack className="mb-2">
                                        <TextField
                                            styles={{fieldGroup: [{height: '32px',}]}}
                                            onChange={setForm} name="usr" label="Username"/>
                                    </Stack>
                                    <Stack className="mb-2">
                                        <TextField
                                            onChange={setForm}
                                            name="pwd"
                                            label="Password"
                                            type="password"
                                            canRevealPassword
                                            styles={{fieldGroup: [{height: '32px',}]}}
                                            revealPasswordAriaLabel="Show password"
                                        />
                                    </Stack>
                                    <Stack className="mb-2" horizontal horizontalAlign={"end"}>
                                        {primaryActionMarkup}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default LoginPage