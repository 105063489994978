import useSafeQuery from "../../../../../hooks/use-safe-query.hook";
import {useGetQuery} from "../../../../../../services/erp-resource-api.service";
import useQueryParams from "../../../../../hooks/use-query-params.hook";
import PageGenerator from "../../../../../common/page-generator/page-generator.component";
import {Stack} from "@fluentui/react";
import {useDispatch} from "react-redux";
import {setDocAction} from "../../../../../../store/actions/pages/doc/doc-page.actions";

const StandardDocPage = (props: any) => {

    const dispatch = useDispatch()
    const id = useQueryParams('id');

    const {data, isSuccess} = useSafeQuery(useGetQuery, {
        doc: props.view_doc_type,
        name: id
    })

    if(data && isSuccess) {
        setTimeout(() => {
            dispatch(setDocAction(data.data))
        }, 1)
    }

    return (
        <Stack>
            <PageGenerator configs={props.layout.configs}/>
        </Stack>
    )
}

export default StandardDocPage