import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    visible: false,
    config: {}
}

const slice = createSlice({
    name: 'panel-app-slice',
    initialState,
    reducers: {
        setVisibility(state, {payload}: any) {
            state.visible = payload.visible
            state.config = payload.config
        }
    },
})

export const {setVisibility} = slice.actions
export default slice.reducer