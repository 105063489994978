import {initializeIcons} from "@fluentui/font-icons-mdl2";
import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import RouterConfigs from "./routes/router-configs";
import {store} from "./store/store";
import ThemeManager from "./components/common/theme-manager/theme-manager.component";

initializeIcons();

export const App: React.FunctionComponent = () => {

    return (
        <Provider store={store}>
            <ThemeManager>
                <BrowserRouter>
                    <RouterConfigs/>
                </BrowserRouter>
            </ThemeManager>
        </Provider>
    );
};
