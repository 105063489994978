import {Dropdown, FontIcon, IDropdownStyles, KeyCodes, Spinner, SpinnerSize} from "@fluentui/react";
import useSafeQuery from "../../../../hooks/use-safe-query.hook";
import {useExecMethodApiQuery} from "../../../../../services/erp-method-api.service";
import {useEffect, useRef} from "react";

const InlineSelectEditor = ({model, editDataQuery, path, setEditMode, onChange, onKeyDown}: any) => {

    const container = useRef(null)

    const {data} = useSafeQuery(useExecMethodApiQuery, {
        method: 'dlad_sap_connector.dlad_sap_connector.api.execute_query',
        body: {name: editDataQuery}
    })

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: {width: 250},
    };

    // useEffect(() => {
    //     if (container.current) {
    //         // @ts-ignore
    //         dropdownStyles.dropdown.width = container.current.offsetWidth - 10
    //     }
    // }, [container])


    const onChangeHandler = (e: any, value: any) => {
        onChange(e, value);
        onKeyDown({keyCode: KeyCodes.enter})
    }

    const markup = data ? (
            <div ref={container} className={'d-flex align-items-center'}>
                <Dropdown
                    placeholder={'Select'}
                    onKeyDown={onKeyDown}
                    options={data.message}
                    onChange={onChangeHandler}
                    styles={dropdownStyles}
                />
                <FontIcon className={'ps-2'} iconName={'Cancel'} onClick={() => onKeyDown({keyCode: KeyCodes.enter})}/>
            </div>

        ) :
        <Spinner size={SpinnerSize.xSmall}/>

    return markup
}

export default InlineSelectEditor