import {useDispatch} from "react-redux";
import useSafeQuery from "../../../../hooks/use-safe-query.hook";
import {useGetQuery} from "../../../../../services/erp-resource-api.service";
import {useEffect, useState} from "react";
import useInput from "../../../../hooks/use-input.hook";
import useAllParams from "../../../../hooks/use-all-params.hook";
import {CommandBar, ICommandBarItemProps, Spinner, TextField} from "@fluentui/react";
import {save} from "../../actions/generic.actions";
import {execAction} from "./actions";
import * as alertActions from "../../../../../store/reducers/general-ui/alert.store";
import Editor from "@monaco-editor/react";
import _ from "lodash";

const QueryConfig = (props: any) => {

    const dispatch = useDispatch()
    const {data} = useSafeQuery(useGetQuery, {doc: props.doctype, name: props.name})
    const [query, setQuery] = useState('')
    const [result, setResult] = useState({})
    const [form, formHandler, formValueHandler, clearAll] = useInput()
    const queryParams: any = useAllParams();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (data && data.data) {
            setQuery(data.data.sql)
        }
    }, [data])

    const saveActionHandler = () => {
        const saveHandler = async () => {
            setLoading(true)
            let model = _.cloneDeep(data.data)
            model.sql = query
            await dispatch(save(props.doctype, data.data.name, model))
            setLoading(false)
        }

        saveHandler()
    }

    const _items: ICommandBarItemProps[] = [
        {
            key: 'save',
            text: 'Save',
            cacheKey: 'save',
            iconProps: {iconName: 'Save'},
            onClick: saveActionHandler
        },
        {
            key: 'exec',
            text: 'Execute',
            cacheKey: 'exec',
            iconProps: {iconName: 'Play'},
            onClick: async () => {
                setLoading(true)
                let response: any = await dispatch(execAction(data.data.name, form))
                setLoading(false)
                if (response.isSuccess) setResult(response.data.message)
                // else dispatch(alertActions.show({title: 'invalid', subtitle: ""}))
            }
        }
    ];

    const onChangeHandler = (value: any) => {
        setQuery(value)
    }

    const editorMarkup = data && data.data ? (
        <Editor
            height="50vh"
            defaultLanguage={'sql'}
            value={(data && data.data) ? data.data.sql : ''}
            onChange={onChangeHandler}
        />
    ) : null

    const inputsMarkup = (data && data.data) ? (
        _.words(data.data.sql, '{(\\w+)}').filter((line: any) => !line.includes('{')).map((line: any) => (
            <div key={line} className="mb-1">
                <TextField label={line} value={form[line]} onChange={formHandler} name={line}/>
            </div>
        ))
    ) : null

    const resultMarkup = result && !loading ?
        (<Editor
            height="30vh"
            defaultLanguage={'json'}
            value={JSON.stringify(result)}
        />) : <Spinner/>

    return (
        <div className={'container gx-0'}>
            <CommandBar
                items={_items}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
            />
            <div className={'py-1'}>
                {editorMarkup}
            </div>
            <div className={'container mb-2'}>
                <div className={'row'}>
                    <div className={'col-4 ms-4'}>
                        {JSON.stringify(queryParams)}
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-4 ms-4'}>
                        {inputsMarkup}
                    </div>
                </div>
            </div>
            <div className={'pt-1'}>
                {resultMarkup}
            </div>

        </div>
    )
}

export default QueryConfig