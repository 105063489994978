import {Outlet} from "react-router";
import ExternalMainContainer from "../components/external-main-container/external-main-container";
import RootUi from "../components/root-ui/root-ui.component";

const DefaultExternalLayout = () => {
    return (
        <RootUi>
            <ExternalMainContainer>
                <Outlet/>
            </ExternalMainContainer>
        </RootUi>
    );
};

export default DefaultExternalLayout;
