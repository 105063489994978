import {Text} from "@fluentui/react";
import {Link} from "react-router-dom";

const LinkTupleCard = (props: any) => {

    return (
        <Text variant={'small'}>
            <Link to={`/app/page/${props.page}?id=${props.text}`}>{props.text}</Link>
        </Text>
    )
}

export default LinkTupleCard