import {IButtonStyles, IconButton, Stack} from "@fluentui/react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/store";
import {getModulesAction} from "../../../../../store/actions/general-ui/general-ui.actions";
import {useNavigate} from "react-router-dom";
import {setSelectedWorkspace} from "../../../../../store/reducers/general-ui/general-ui.store";

const styles: Partial<IButtonStyles> = {
    root: {
        borderRadius: 0,
        height: 48,
    },

    icon: {
        color: "#605e5c",
    },
};

export const ModuleSidebar: React.FunctionComponent<any> = () => {

        const dispatch = useDispatch()

        const {modules} = useSelector<RootStore, any>((state) => state.uiStore.sidebar)
        const {currentSelectedWorkspace} = useSelector<RootStore, any>((state) => state.uiStore.ui)

        if (!modules) {
            dispatch(getModulesAction())
        }

        if (modules && !currentSelectedWorkspace) {
            setTimeout(() => {
                let line = modules.find((line: any) => line.is_default)
                dispatch(setSelectedWorkspace(line.name))
            }, 1)
        }

        const moduleListMarkup = modules &&
            modules.map((line: any, index: number) => (
                <IconButton
                    key={index}
                    styles={styles}
                    iconProps={{iconName: line.icon}}
                    role="button"
                    className={"__module-icon w-100"}
                    onClick={() => {
                        dispatch(setSelectedWorkspace(line.name))
                    }}
                />
            ))

        return (
            <Stack className={"__module-container d-flex flex-column h-100 border-end"}>
                {moduleListMarkup}
            </Stack>
        );
    }
;
