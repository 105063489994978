import {createSlice} from '@reduxjs/toolkit'

interface IModalState {
    isLoading: boolean,
    appConfig: any,
    currentSelectedWorkspace: any,
    workspace: any,
    page: any,
    theme: any
}

const initialState = {
    isLoading: true,
    appConfig: null,
    currentSelectedWorkspace: null,
    workspace: null,
    page: null,
    theme: '365'
} as IModalState

const slice = createSlice({
    name: 'general-ui-store',
    initialState,
    reducers: {
        showLoading(state) {
            state.isLoading = true
        },
        hideLoading(state) {
            state.isLoading = false
        },
        setAppConfig(state, {payload}: any) {
            state.appConfig = payload
        },
        setSelectedWorkspace(state, {payload}: any) {
            state.currentSelectedWorkspace = payload
        },
        setWorkspace(state, {payload}: any) {
            state.workspace = payload
        },
        setPage(state, {payload}: any) {
            state.page = payload
        },
        setTheme(state, {payload}: any) {
            state.theme = payload
        }
    },
})

export const {showLoading, setSelectedWorkspace, setAppConfig, setWorkspace, setPage, setTheme} = slice.actions
export default slice.reducer