import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/root.reducer";
import {erpResourceApi} from "../services/erp-resource-api.service";
import {erpMethodApi} from "../services/erp-method-api.service";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    // getDefaultMiddleware().concat(usersApi.middleware);
    return getDefaultMiddleware()
        .concat(erpResourceApi.middleware)
        .concat(erpMethodApi.middleware)
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootStore = ReturnType<typeof store.getState>;
