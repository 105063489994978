import GoogleMap from "../../../google-maps/google-maps.app";
import useSafeQuery from "../../../../hooks/use-safe-query.hook";
import {useListQuery} from "../../../../../services/erp-resource-api.service";
import {useDispatch, useSelector} from "react-redux";
import {setLocations} from "../../../../../store/reducers/apps/maps/google-map.store";
import {DatePicker, defaultDatePickerStrings, IDatePickerStyles, Stack} from "@fluentui/react";
import moment from "moment";
import {useEffect, useState} from "react";
import {getFilteredSiteVisits, getGeoDodeList} from "../../../google-maps/actions/google-map.actions";

const SiteVisitMap = () => {

    const dispatch = useDispatch()
    const {data} = useSafeQuery(useListQuery, {method: 'DLAD Site Visit', fields: ['name', 'lat', 'long']})
    const [selectedDate, setSelectedDate] = useState('')

    setTimeout(() => {
        dispatch(setLocations({lat: '-37.73289395276815', long: '144.8350208117412'}))
    }, 1000)

    return (
        <>
            <div className={"py-1 pb-2 px-2"}>
                <Stack horizontal>
                    <FilterDatePicker onChange={setSelectedDate}/>
                </Stack>
                <br/>
            </div>
            <MapWrapper date={selectedDate}/>
        </>

    )
}

const styles: Partial<IDatePickerStyles> = {textField: {width: 300}};


const MapWrapper = ({date}: any) => {

    const dispatch = useDispatch()
    const [geocodes, setGeoCodes] = useState([])

    useEffect(() => {
        getSiteVisitList()
    }, [date])

    const getSiteVisitList = async () => {
        let siteVisitList: any = await dispatch(getFilteredSiteVisits(date));
        let geocodes: any = await dispatch(getGeoDodeList(siteVisitList?.data))
        setGeoCodes(geocodes)
    }

    return <GoogleMap geocodes={geocodes}/>
}

const FilterDatePicker = ({onChange}: any) => {

    const _onSelectDate = (value: any) => {
        onChange(moment(value).format('YYYY-MM-DD'))
    }

    return (
        <DatePicker
            label={"Select Date"}
            className={'me-2'}
            placeholder="Select a date..."
            ariaLabel="Select a date"
            strings={defaultDatePickerStrings}
            onSelectDate={_onSelectDate}
            styles={styles}
        />
    )
}

export default SiteVisitMap