import {useDispatch} from "react-redux";
import useInput from "../../../../hooks/use-input.hook";
import {CommandBar, DocumentCardDetails, Dropdown, Label, Pivot, PivotItem, Stack, TextField} from "@fluentui/react";
import {createSiteVisitAction} from "./acctions/site-visit.actions";
import RelationalField from "../../../../common/form-components/relational-fields/relational-field.component";
import AutoCompleteTextField
    from "../../../../common/form-components/auto-compolete-text-field/auto-complete-text-field";
import React, {useEffect, useState} from "react";
import {getDocumentList} from "../../../../common/form-components/actions/auto-compleate-text-field.actions";
import DateTimePicker from "../../../../common/form-components/data-time-picker/date-time-picker.component";
import {useNavigate} from "react-router-dom";

const CreateSiteVisitsPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [form, formHandler, formValueHandler, clearAll] = useInput()
    const [address, setAddress] = useState({})

    const dropDownHandler = (e: any, option: any, index: any) => {
        formValueHandler(e.target.id, option.text)
    }

    const relationalFieldHandler = ({key, text}: any) => {
        formValueHandler(key, text)
    }

    const relationalFieldPartyName = ({key, text, keyValue}: any) => {
        formValueHandler(key, text)
        formValueHandler('party_name', keyValue.text)
    }

    const save = async () => {
        let model = {
            ...form,
            ...address
        }
        dispatch(createSiteVisitAction(model, clearAll, navigate))
    }

    const commandBarMarkup = (
        <div className={'mx-2 mb-2'}>
            <CommandBar
                items={[{
                    key: 'save',
                    text: 'Save',
                    iconProps: {iconName: 'Save'},
                    onClick: () => {
                        save()
                    },
                    ['data-automation-id']: 'newSaveButton', // optional
                }]}
                overflowButtonProps={{ariaLabel: "More commands"}}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
            />
        </div>
    )

    const basicInfoMarkup = (
        <Stack className="pb-1 px-2">
            <DocumentCardDetails className={'px-2 bg-white rounded-2'}>
                <div className={'row py-2'}>
                    <div className={'col-lg-4'}>
                        <RelationalField
                            name={{
                                main: {key: 'type', label: 'Site Visit Type'},
                                sub: {key: 'party', label: 'Party'}
                            }}
                            onChangeMain={relationalFieldHandler}
                            onChangeSub={relationalFieldPartyName}
                            options={{
                                main: {
                                    query: '3df621b418'
                                }
                            }}
                            docConfig={[
                                {
                                    label: 'Business Partner',
                                    doc: "Business Partner",
                                    type: "erp",
                                    query: '3df621b418'
                                },
                                {
                                    label: 'Leads',
                                    doc: "Leads",
                                    type: "Standard",
                                    query: 'DLAD Site Visit'
                                }
                            ]}
                        />
                        {
                            form['type'] == 'Business Partner'
                                ? <AutoCompleteTextField label={"Address"} id={'address'} onChange={formValueHandler}
                                                         options={{
                                                             type: "erp",
                                                             query: {name: '0818dfcaa4', id: form['party']}
                                                         }}
                                />
                                : <AutoCompleteTextField keyValuePair={{key: 'name', text: 'name'}} label={"Address"}
                                                         id={'address'} onChange={formValueHandler}
                                                         options={{
                                                             type: "Standard",
                                                             query: "DocType"
                                                         }}
                                />
                        }

                        <Address refAddress={form['address']} id={form['party']}
                                 onChange={setAddress}
                        />

                    </div>
                    <div className={'col-lg-4'}>
                        <div className={'col-lg'}>
                            <Dropdown
                                className={'me-2'}
                                placeholder="Priority"
                                label="Priority"
                                id={'priority'}
                                onChange={dropDownHandler}
                                options={[
                                    {key: 'High', text: 'High'},
                                    {key: 'Low', text: 'Low'},
                                ]}
                            />
                            <DateTimePicker label={'Start Time'} id={'start_time'} onChange={formValueHandler}/>
                            <DateTimePicker label={'End Time'} id={'end_time'} onChange={formValueHandler}/>
                        </div>
                    </div>
                    <div className="col-lg-4">
                    </div>
                </div>
            </DocumentCardDetails>
        </Stack>
    )

    const tabMarkup = (
        <Pivot
            overflowBehavior={'menu'}>

            <PivotItem
                headerText="Basic Information"
                headerButtonProps={{
                    'data-order': 1,
                }}
            >
                {basicInfoMarkup}
            </PivotItem>
        </Pivot>
    )

    return <>
        {commandBarMarkup}
        {tabMarkup}
    </>


}
const Address = React.memo(({id, refAddress, onChange}: any) => {
    const dispatch = useDispatch()
    const [address, setAddress] = useState({} as any)

    useEffect(() => {
        getDocument()
    }, [id, refAddress])

    const style = {color: 'black'}

    const getDocument = async () => {
        let model: any = await dispatch(getDocumentList({name: '16671ee893', id: id}))
        console.log(model)
        let address = model.find((line: any) => line.Address == refAddress)
        setAddress(address)
        onChange(
            {
                address: address?.Address,
                city: address?.City,
                state_province: address?.State,
                postal_code: address?.ZipCode,
                street: address?.Street
            }
        )
    }

    const addressMarkup = address && <Stack>
        <Label>Street</Label>
        <TextField style={style} value={address?.Street} disabled={true}/>
        <Label>City</Label>
        <TextField style={style} value={address?.City} disabled={true}/>
        <Label>State / Province</Label>
        <TextField style={style} value={address?.State} disabled={true}/>
        <Label>Postal Code</Label>
        <TextField style={style} value={address?.ZipCode} disabled={true}/>
    </Stack>

    return <>
        {addressMarkup}
    </>
})

export default CreateSiteVisitsPage