import {useLocation, useParams} from "react-router-dom";

const useAllParams = () => {
    
    const useQuery = () => new URLSearchParams(useLocation().search);
    let queryParam = useQuery()
    let pathParams = useParams()

    let queryParams = {}
    queryParam.forEach((value, key) => {
        queryParams = {
            ...queryParams,
            [key]: value
        }
    })

    return queryParams
}

export default useAllParams
