import {CommandBar, ICommandBarItemProps, setVirtualParent, Spinner, TextField} from "@fluentui/react";
import useSafeQuery from "../../../../hooks/use-safe-query.hook";
import {useGetQuery} from "../../../../../services/erp-resource-api.service";
import Editor from "@monaco-editor/react";
import {useDispatch} from "react-redux";
import {save} from "../../actions/generic.actions";
import {execAction} from "../query-config/actions";
import {useEffect, useState} from "react";
import _ from 'lodash'
import useInput from "../../../../hooks/use-input.hook";
import useAllParams from "../../../../hooks/use-all-params.hook";
import * as alertActions from "../../../../../store/reducers/general-ui/alert.store";
import {setVisibility} from "../../../../../store/reducers/apps/admin/panel.store";

const NumberCardConfig = (props: any) => {

    const dispatch = useDispatch()
    const {data} = useSafeQuery(useGetQuery, {doc: props.doctype, name: props.name})
    const [result, setResult] = useState({})
    const [form, formHandler, formValueHandler, clearAll] = useInput()
    const queryParams: any = useAllParams();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // queryParams.forEach((line: any) => {
        //     formHandler({target: {name: 'id', value: line['id']}})
        // })

        // for (const [key, value] of Object.entries(queryParams)) {
        //     formHandler({target: {name: key, value: value}})
        // }

    },)

    const inputsMarkup = (data && data.data) ? (
        Object.entries(data.data).map((line: any) => (
            <div key={line} className="mb-1">
                <TextField label={line[0]} value={line[1]} onChange={formHandler} name={line[0]}/>
            </div>
        ))
    ) : null


    const _items: ICommandBarItemProps[] = [
        {
            key: 'save',
            text: 'Save',
            cacheKey: 'save',
            iconProps: {iconName: 'Save'},
            onClick: () => {
                dispatch(save('DLAD Query', data.data.name, data))
            }
        },
        {
            key: 'viewQuery',
            text: 'View Query',
            cacheKey: 'viewQuery',
            iconProps: {iconName: 'Play'},
            onClick: () => {
                dispatch(setVisibility({visible: true, config: {name: props.query, doctype: 'DLAD Query'}}))
            }
        }
    ];

    return (
        <div className={'container gx-0'}>
            <CommandBar
                items={_items}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
            />
            {inputsMarkup}
        </div>
    )
}

export default NumberCardConfig;