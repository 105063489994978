import {DetailsListLayoutMode, mergeStyleSets} from "@fluentui/react";
import React, {useEffect, useState} from "react";
import {EditableGrid, EditControlType} from "fluentui-editable-grid";

const TableHeaderEditor = (props: any) => {

    const [items, setItems] = useState<any[]>(props.model);
    const columns = [
        {
            key: 'idx',
            name: 'idx',
            text: 'Sequence',
            editable: false,
            dataType: 'number',
            isResizable: true,
            includeColumnInExport: true,
            includeColumnInSearch: true,
            applyColumnFilter: true
        },
        {
            key: 'key',
            name: 'key',
            text: 'Path',
            editable: true,
            dataType: 'number',
            isResizable: true,
            includeColumnInExport: true,
            includeColumnInSearch: true,
            applyColumnFilter: true
        },
        {
            key: 'label',
            name: 'label',
            text: 'Title',
            editable: true,
            dataType: 'text',
            isResizable: true,
            includeColumnInExport: true,
            includeColumnInSearch: true,
            applyColumnFilter: true
        },
        {
            key: 'min_width',
            name: 'min_width',
            text: 'Min Width',
            editable: true,
            dataType: 'text',
            isResizable: true,
            includeColumnInExport: true,
            includeColumnInSearch: true,
            applyColumnFilter: true
        },
        {
            key: 'max_width',
            name: 'max_width',
            text: 'Max Width',
            editable: true,
            dataType: 'text',
            isResizable: true,
            includeColumnInExport: true,
            includeColumnInSearch: true,
            applyColumnFilter: true
        }
    ];

    useEffect(() => {
        setItems(props.model)
    }, [props])

    return (
        <>
            <EditableGrid
                id={1}
                columns={columns as any}
                items={items}
                enableCellEdit={true}
                enableExport={true}
                enableTextFieldEditMode={true}
                enableTextFieldEditModeCancel={true}
                enableGridRowsDelete={true}
                enableGridRowsAdd={true}
                // position={'relative'}
                enableUnsavedEditIndicator={true}
                compact={true}
                //onGridSave={onGridSave}
                enableGridReset={true}
                enableColumnFilters={true}
                enableColumnFilterRules={true}
                enableRowAddWithValues={{enable: true, enableRowsCounterInPanel: true}}
                layoutMode={DetailsListLayoutMode.justified}
                enableRowEdit={true}
                enableRowEditCancel={true}
                selectionMode={0}
                enableBulkEdit={true}
                enableColumnEdit={true}
                enableSave={true}
                dragDropEvents={props.dragDropEvents}
            />
        </>
    )
}

export default TableHeaderEditor