import {Dropdown, TextField} from "@fluentui/react";
import {useEffect, useState} from "react";
import moment from "moment";

interface IProps {
    label: string
    id: string
    onChange: any
    required?: boolean
}

const DateTimePicker = ({label, id, onChange, required = false}: IProps) => {
    const [date, setDate] = useState('00-00-0000' as any)
    const [sendData, setSendDate] = useState('00-00-0000' as any)
    const [time, setTime] = useState('00:00' as any)

    useEffect(() => {
        onChange(id, sendData + ' ' + time)
    }, [date, time])

    const dataHandler = (e: any) => {
        setDate(e.target.value)
        setSendDate(moment(e.target.value).format('DD-MM-YYYY'))
    }

    const dropDownHandler = (e: any, option: any, index: any) => {
        setTime(option.key)
    }

    const markup = <div className={'d-flex align-items-end'}>
        <TextField className={'me-2'}
                   required={required}
                   value={date} type={'date'}
                   onChange={dataHandler}
                   name={'start_time'}
                   label={label}/>
        <Dropdown
            placeholder="Time"
            id={'subject'}
            onChange={dropDownHandler}
            options={[
                {key: '06:15', text: '06:15'},
                {key: '06:30', text: '06:30'},
                {key: '06:45', text: '06:45'},
                {key: '07:00', text: '07:00'},
                {key: '07:15', text: '07:15'},
                {key: '07:30', text: '07:30'},
                {key: '07:45', text: '07:45'},
                {key: '08:00', text: '08:00'},
                {key: '08:15', text: '08:15'},
                {key: '08:30', text: '08:30'},
                {key: '08:45', text: '08:45'},
                {key: '09:00', text: '09:00'},
                {key: '09:15', text: '09:15'},
                {key: '09:30', text: '09:30'},
                {key: '09:45', text: '09:45'},
                {key: '10:00', text: '10:00'},
                {key: '10:15', text: '10:15'},
                {key: '10:30', text: '10:30'},
                {key: '10:45', text: '10:45'},
                {key: '11:00', text: '11:00'},
                {key: '11:15', text: '11:15'},
                {key: '11:30', text: '11:30'},
                {key: '11:45', text: '11:45'},
                {key: '12:00', text: '12:00'},
                {key: '12:15', text: '12:15'},
                {key: '12:30', text: '12:30'},
                {key: '12:45', text: '12:45'},
                {key: '13:00', text: '13:00'},
                {key: '13:15', text: '13:15'},
                {key: '13:30', text: '13:30'},
                {key: '13:45', text: '13:45'},
                {key: '14:00', text: '14:00'},
                {key: '14:15', text: '14:15'},
                {key: '14:30', text: '14:30'},
                {key: '14:45', text: '14:45'},
                {key: '15:00', text: '15:00'},
                {key: '15:15', text: '15:15'},
                {key: '15:30', text: '15:30'},
                {key: '15:45', text: '15:45'},
                {key: '16:00', text: '16:00'},
                {key: '16:15', text: '16:15'},
                {key: '16:30', text: '16:30'},
                {key: '16:45', text: '16:45'},
                {key: '17:00', text: '17:00'},
                {key: '17:15', text: '17:15'},
                {key: '17:30', text: '17:30'},
                {key: '17:45', text: '17:45'},
                {key: '18:00', text: '18:00'},
            ]}
        />
    </div>

    return <>
        {markup}
    </>
}

export default DateTimePicker